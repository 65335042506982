import { Injectable, NgZone } from '@angular/core';

/**
 * Système d'évènement
 */

@Injectable({
    providedIn: 'root'
})
export class EventsService
{
    private _listeners: any = {};
    private _ngZone: NgZone | null = null;

    init(ngZone: NgZone): void
    {
        this._ngZone = ngZone;
    }

    addListener(name: string, obj: any, fct: any): void
    {
        (this._listeners[name] = (this._listeners[name] || [])).push({ obj: obj, fct: fct });
    }

    removeListener(name: string, obj: any, fct: any)
    {
        if (arguments.length === 1)
        { // remove all
            this._listeners[name] = [];
        }
        else if (typeof (fct) === 'function')
        {
            var listeners = this._listeners[name];
            if (listeners !== undefined)
            {
                var foundAt = -1;
                for (var i = 0, len = listeners.length; i < len && foundAt === -1; i++)
                {
                    if (listeners[i].fct === fct && listeners[i].obj === obj)
                    {
                        foundAt = i;
                    }
                }

                if (foundAt >= 0)
                {
                    listeners.splice(foundAt, 1);
                }
            }
        }
    }

    sendEvent(name: string, args: any = null): void
    {
        //console.log("send " + name+((args!=null)?(" "+JSON.stringify(args)):""));
        (this._ngZone as NgZone).run(() =>
        {
            var listeners = this._listeners[name];
            args = args || [];
            if (listeners !== undefined)
            {
                listeners = listeners.slice(0);
                for (var i = 0; i < listeners.length; i++)
                    listeners[i].fct.apply(listeners[i].obj, args);
            }
        });
    }

    hasListeners(name: string): boolean
    {
        return (this._listeners[name] === undefined ? 0 : this._listeners[name].length) > 0;
    }

    constructor() { }
}
