import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService
{
  active: boolean = false;

  constructor(data: DataService) { }

  request()
  {
    var _documentElement: any = document.documentElement;
    if (typeof _documentElement == 'undefined')
      return;
    if (_documentElement.requestFullscreen)
      _documentElement.requestFullscreen();
    else if (_documentElement.mozRequestFullScreen)
      _documentElement.mozRequestFullScreen();
    else if (_documentElement.webkitRequestFullScreen)
      _documentElement.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
    else if (_documentElement.msRequestFullscreen)
      _documentElement.msRequestFullscreen();
  }

  exit()
  {
    var _document: any = document;
    if (_document.exitFullscreen)
      _document.exitFullscreen();
    else if (_document.mozCancelFullScreen)
      _document.mozCancelFullScreen();
    else if (_document.webkitExitFullscreen)
      _document.webkitExitFullscreen();
    else if (_document.msExitFullscreen)
      _document.msExitFullscreen();
  }
  
  toggle()
  {
    if (this.active)
      this.exit();
    else
      this.request();
      this.active = !this.active;
  }

}
