import { Component, OnInit, OnChanges, Input, HostBinding, SimpleChanges, HostListener, Output, EventEmitter, ElementRef, NgZone } from '@angular/core';
import { Global } from 'src/app/global';
import { AudioService } from 'src/app/services/audio.service';
import { EventsService } from 'src/app/services/events.service';

import gsap from 'gsap';
import { DataService } from 'src/app/services/data.service';

/**
 * Système générique de bouton
 * 
 */

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges
{
  // clé pour le texte principal
  @Input('key') key: string | null = null;

  // texte principal direct, éventuellement
  @Input('txt') txt: string | null = null;

  // type du bouton
  @Input('type') type = 'default';

  // permet de rendre le bouton inactif
  @Input('inactive') inactive: boolean = false;

  // permet des changements d'état du bouton
  @Input('mode') mode: string = "";

  // handler pour le click
  @Output() handler = new EventEmitter<string>();


  @HostBinding('class') classname = '';

  clicking: boolean = false;
  timeout: any = null;

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private audio: AudioService,
    private ref: ElementRef,
    private ngZone: NgZone
  ) { }

  ngOnInit()
  {
    this.events.addListener("EVENT_BURGER_MODE", this, this._event_burger_mode);

    this._updateTxt();
    this._updateClassname();
  }

  ngOnDestroy()
  {
    // suppression du timeout en cours s'il y en a un 
    if (this.timeout != null) clearTimeout(this.timeout);
    this.events.removeListener("EVENT_BURGER_MODE", this, this._event_burger_mode);
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    // un changement de type implique un changement de classe
    for (var key in changes)
    {
      if (key == 'type') this._updateClassname();
      if (key == 'key') this._updateTxt();
      if (key == 'mode') this._updateClassname();
      if (key == 'inactive') this._updateClassname();
    }
  }

  _updateTxt() 
  {
    if (this.key != null)
      this.txt = this.data.translate(this.key);
  }

  _updateClassname()
  {
    var _classname = this.type;
    if (this.clicking || this.inactive || this.type == "header") _classname += " noevents";
    if (this.type == "header") _classname += " yellow-light";
    if (this.mode.length > 0) _classname += " " + this.type + "-" + this.mode;
    this.classname = _classname;
  }

  _event_burger_mode(a_params: { mode?: string })
  {
    // permet de faire évoluer l'apparence et le traitement du bouton
    if (this.type == "burger")
    {
      this.mode = (a_params.mode != null) ? a_params.mode : "";
      this._updateClassname();
    }
  }

  @HostListener('click', [])
  onClick()
  {
    // anti-spam
    this.clicking = true;
    this._updateClassname();

    // si le bouton est appelé à être cliqué à nouveau, on lance un timer pour le réactiver
    this.timeout = setTimeout(function () { _this.ngZone.run(() => { _this.clicking = false; _this._updateClassname(); }) }, 500);

    //this.audio.playAction();
    var _this = this;
    var _tl = gsap.timeline();

    _tl.to(this.ref.nativeElement, { duration: this.global.BUTTON_SCALE_DURATION, scale: this.global.BUTTON_SCALE_DOWN, ease: "power2.out" });
    _tl.call(function ()
    {
      // pas de son bouton sur le burger ou quick
      if (_this.type!="burger" && _this.type!="quick")
        _this.audio.playButton();
    });

    _tl.to(this.ref.nativeElement, { duration: this.global.BUTTON_SCALE_DURATION, scale: 1.0, ease: "power2.in" });
    _tl.call(function ()
    {
      _this.handler.emit(_this.mode);
    });
  }

  @HostListener('mouseenter', [])
  onMouseEnter()
  {
    // scale plus faible si bouton plus large
    var _scale:number = (this.type.indexOf("-large")!=-1)?this.global.BUTTON_SCALE_UP_LARGE:this.global.BUTTON_SCALE_UP;
    gsap.to(this.ref.nativeElement, { duration: this.global.BUTTON_SCALE_DURATION, scale: _scale, ease: "power2.out" });
  }

  @HostListener('mouseleave', [])
  onMouseLeave()
  {
    // seulement si on a pas déjà clické
    if (!this.clicking)
      gsap.to(this.ref.nativeElement, { duration: this.global.BUTTON_SCALE_DURATION, scale: 1.0, ease: "power2.in" });
  }

}
