<div class="bg" *ngIf="popup" (click)="onBackground()"></div>
<app-button type="header" key="ui.menu.ressources"></app-button>
<div class="main">
    <div class="side">
        <div *ngFor="let notion of notions; let index = index;" (click)="onClick(notion.id, index)" class="line yellow-light">
            <div class="selected" *ngIf="selected==notion.id"></div>
            <div class="text">{{notion.txt}}</div>
        </div>
    </div>
    <div class="content">
        <overlay-scrollbars #scrollbars>
            <div class="inner">
                <div class="title" [innerHTML]="title"></div>
                <div class="text" [innerHTML]="text"></div>
                <div class="links" *ngIf="links.length>0">
                    <p>{{'ui.ressources.link'|wording}}</p>
                    <a *ngFor="let link of links" [href]="link.description" [innerHTML]="link.title"
                        target="_blank"></a>
                </div>

            </div>
        </overlay-scrollbars>
    </div>
</div>