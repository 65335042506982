import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from './services/data.service';

@Pipe({
  name: 'wording'
})
export class WordingPipe implements PipeTransform
{

  constructor(private data: DataService)
  { }

  transform(value: unknown, ...args: unknown[]): unknown
  {
    return this.data.translate(value as string);
  }

}
