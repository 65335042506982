import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';

import { Global, Status } from 'src/app/global';
import { AudioService } from 'src/app/services/audio.service';
import { EngineService } from 'src/app/services/engine.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { EventsService } from 'src/app/services/events.service';

/**
 * Ecran de bilan de fin de partie
 * Découpé en 3 slides
 * 
 */

@Component({
  selector: 'app-ending',
  templateUrl: './ending.component.html',
  styleUrls: ['./ending.component.scss']
})
export class EndingComponent implements OnInit
{
  slide: number = 0;
  inc: number = 0;

  toggles: Array<boolean> = [false, false, false, false, false, false, false, false];
  message1: string = "";
  message2: string = "";

  constructor(
    private global: Global,
    private data: DataService,
    private storage: StorageService,
    private events: EventsService,
    private audio: AudioService,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.events.sendEvent("EVENT_TRANSITION_END");
    this.events.addListener("EVENT_TRANSITION_WAIT", this, this._event_transition_wait);
    this.events.addListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);

    // debug
    if (this.global.dev && this.slide > 0)
      for (var i = 0; i < this.toggles.length; i++)
        this.toggles[i] = Math.random() < 0.5;
    this._initSlide();
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_TRANSITION_WAIT", this, this._event_transition_wait);
    this.events.removeListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);
  }

  // transition sur le prochain slide
  _event_transition_wait()
  {
    if (this.inc != 0)
    {
      this.slide += this.inc;
      this._initSlide();
      this.inc = 0;

      this.events.sendEvent("EVENT_TRANSITION_END");
    }
  }

  // fin de la transition
  _event_transition_ended()
  {
    // fin de la personnalisation du temps de transition lors de l'outro
    this.global.overrideTransitionDuration = null;
  }

  _initSlide()
  {
    if (this.slide == 2)
    {
      // sauvegarde des notions maitrisés sur le dernier slide, au cas où
      var _notionsAcquired: Array<number> = [];
      var _notions: Array<number> = [];
      var _others: Array<number> = [];
      for (var i = 0; i < this.toggles.length; i++)
      {
        if (this.toggles[i]) 
        {
          _notions.push(i + 1);
          _notionsAcquired.push(1);
        }
        else 
        {
          _others.push(i + 1);
          _notionsAcquired.push(0);
        }
      }
      this.data.user.notions = _notions;
      this.data.save();

      // notification serveur
      this.storage.requestWebService("saveNotionsAcquired", null, {
        userUID: this.data.uid,
        notions: JSON.stringify(_notionsAcquired)
      })

      // et mise à jour des messages
      if (_notions.length > 0)
        this.message1 = this.data.translate("ui.bilan3.bloc.text1").replace("[]", "<span class='white'>" + _notions.join(',') + "</span>");
      else
        this.message1 = this.data.translate("ui.bilan3.bloc.text1.bis");
      if (_others.length > 0)
        this.message2 = this.data.translate("ui.bilan3.bloc.text2").replace("[]", "<span class='white'>" + _others.join(',') + "</span>");
      else
        this.message2 = this.data.translate("ui.bilan3.bloc.text2.bis");
    }
  }

  onPrev()
  {
    if (this.slide > 0) this.inc = -1;
    this.events.sendEvent("EVENT_TRANSITION_SAME");
  }

  onDownload()
  {
    const _url_base: string = (this.global.dev ? (this.global.URL_WEBSERVICE_DEV + "/") : "");
    const _url = _url_base + "export_bilan/" + this.data.uid;
    window.open(_url, "_blank");
  }

  onNext()
  {
    if (this.slide < 2) this.inc = 1;
    this.events.sendEvent("EVENT_TRANSITION_SAME");
  }

  onToggle(n: number)
  {
    this.toggles[n] = !this.toggles[n];
  }

  onHome()
  {
    this.global.gotoStatus(Status.home);
  }
}
