import { NgModule } from '@angular/core';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { Global } from './global';
import { TransitionComponent } from './shared/transition/transition.component';
import { BackgroundComponent } from './shared/background/background.component';
import { ButtonComponent } from './shared/button/button.component';

import { LoadingComponent } from './pages/loading/loading.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { PlayingComponent } from './pages/playing/playing.component';
import { LogoComponent } from './pages/logo/logo.component';
import { SlideshowComponent } from './pages/slideshow/slideshow.component';
import { EndingComponent } from './pages/ending/ending.component';

import { TalkerComponent } from './components/talker/talker.component';
import { PlayerComponent } from './components/player/player.component';
import { EllipseComponent } from './components/ellipse/ellipse.component';
import { ClickableComponent } from './components/clickable/clickable.component';
import { MenuComponent } from './components/menu/menu.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { WordingPipe } from './wording.pipe';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { InfosComponent } from './components/infos/infos.component';
import { WarningComponent } from './pages/warning/warning.component';


@NgModule({
  declarations: [
    AppComponent,

    TransitionComponent,
    BackgroundComponent,
    ButtonComponent,

    LoadingComponent,
    LoginComponent,
    HomeComponent,
    PlayingComponent,
    TalkerComponent,
    PlayerComponent,
    EllipseComponent,
    ClickableComponent,
    MenuComponent,
    ResourcesComponent,
    WordingPipe,
    ConfirmComponent,
    LogoComponent,
    InfosComponent,
    SlideshowComponent,
    EndingComponent,
    WarningComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OverlayscrollbarsModule
  ],
  providers: [Global],
  bootstrap: [AppComponent]
})
export class AppModule { }
