import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { Global, Status } from 'src/app/global';
import { AudioService } from 'src/app/services/audio.service';
import { EngineService } from 'src/app/services/engine.service';
import { DataService } from 'src/app/services/data.service';

import gsap from 'gsap';

/**
 * Ecran d'accueil principal
 * 
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit
{
  newgame: boolean = false;

  @ViewChild("title") titleRef!: ElementRef;
  @ViewChild("message") messageRef!: ElementRef;
  @ViewChild("buttons") buttonsRef!: ElementRef;
  @ViewChild("links") linksRef!: ElementRef;


  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private engine: EngineService,
    private audio: AudioService) { }

  ngOnInit(): void
  {
    this.events.sendEvent("EVENT_TRANSITION_END");

    // preloading background pour le lancement du jeu
    this.engine.preload();

    // on regarde s'il y a une sauvegarde précédente
    this.newgame = (this.data.user.progress["node"] == null);

    // debug
    //this.newgame = true;
  }

  // animation d'apparition
  ngAfterViewInit(): void
  {
    this.audio.playBg("ui", true);

    // mise en place du texte
    this.messageRef.nativeElement.innerHTML = this.data.session?.text_home_page?.length>0?this.data.session.text_home_page:this.data.translate('ui.home.text', "");

    var _this = this;
    var _tl = gsap.timeline().addLabel("start");
    _tl.to(this.titleRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_TITLE_DURATION });
    _tl.to(this.messageRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "+=" + this.global.UI_TITLE_WAIT);
    _tl.to(this.buttonsRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "<" + this.global.UI_TITLE_DELAY);
    _tl.to(this.linksRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "<" + this.global.UI_TITLE_DELAY);

    // affichage du burger sur la home
    _tl.call(function(){
      _this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: false }]);
    })
  }

  ngOnDestroy(): void
  {
  }

  onPlay()
  {
    // lancement d'une partie normale
    // avec éventuellement l'intro d'abord si c'est la première fois
    var _status: Status = Status.playing;
    if (this.newgame) _status = Status.intro;

    // debug
    //_status = Status.outro;
    this.audio.stopBg();

    this.global.gotoStatus(_status);
  }

  onRestart()
  {
    // message de confirmation pour lancer une nouvelle partie
    this.events.sendEvent("EVENT_CONFIRM_SHOW", [{ show: 'restart' }])
  }

  onMentions()
  {
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }]);
    this.events.sendEvent("EVENT_INF_POPUP_SHOW", [{ show: "mentions" }]);
  }

  onConfidentialite()
  {
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }]);
    this.events.sendEvent("EVENT_INF_POPUP_SHOW", [{ show: "confidentialite" }]);
  }
}
