<!-- écran 1 -->
<div [class]="'slide slide'+slide" *ngIf="slide==0">

    <div class="title"></div>
    <div class="complete green-light">{{'ui.bilan1.title1'|wording}}</div>

    <div class="message">
        <p [innerHTML]="'ui.bilan1.bloc.text1'|wording"></p>
    </div>

    <app-button class="next" type="yellow-small" key="ui.btn.suivant" (handler)="onNext()"></app-button>

</div>

<!-- écran 2 -->
<div [class]="'slide slide'+slide" *ngIf="slide==1">

    <div class="message yellow-light">
        <p [innerHTML]="'ui.bilan2.bloc.text1'|wording"></p>
    </div>

    <div class="lines">
        <div class="line" *ngFor="let n of [1,2,3,4,5,6,7,8]">
            <div [class]="'icon icon'+n"></div>
            <div class="label white-light" [innerHTML]="'ui.bilan2.ligne.text'+n|wording"></div>
            <app-button type="toggle" [mode]="toggles[n-1]?'yes':'no'" (handler)="onToggle(n-1)"></app-button>
        </div>
    </div>

    <app-button class="next" type="yellow-small" key="ui.bilan2.btn.confirm" (handler)="onNext()"></app-button>

</div>

<!-- écran 3 -->
<div [class]="'slide slide'+slide" *ngIf="slide==2">

    <div class="title"></div>

    <div class="messages">
        <p class="message1 yellow-light" [innerHTML]="message1"></p>
        <p class="message2 yellow-light" *ngIf="message2.length>0" [innerHTML]="message2"></p>
        <p class="message3 yellow-light" [innerHTML]="'ui.bilan3.bloc.text3'|wording"></p>
        <p class="message4 white-light" [innerHTML]="'ui.bilan3.bloc.text4'|wording"></p>
    </div>

    <app-button class="prev" type="yellow-small" key="ui.bilan3.btn.back" (handler)="onPrev()"></app-button>

    <app-button class="pdf" type="yellow" key="ui.bilan3.btn.download" (handler)="onDownload()"></app-button>

    <app-button class="next" type="yellow-small" key="ui.bilan3.btn.home" (handler)="onHome()"></app-button>

</div>