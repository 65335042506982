import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Global, Status } from 'src/app/global';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';
import { EngineService } from 'src/app/services/engine.service';

import gsap from 'gsap';
import { DataService } from 'src/app/services/data.service';
import { AudioService } from 'src/app/services/audio.service';

/**
 * Composant de gestion de l'arrière plan
 * 
 * Essentiellement utilisée pour le jeu
 * mais peut être utilisé hors jeu également
 * 
 */

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit
{
  location: string = "";

  background: string | null = null;
  next_background: string | null = null;
  src: any = null;

  @ViewChild('img') imgRef!: ElementRef;

  ingame: boolean = false;

  // preloading
  preloaded: string = "";
  preload: HTMLImageElement = new Image();

  music: string | null = null;

  constructor(
    private global: Global,
    private audio: AudioService,
    private events: EventsService,
    private storage: StorageService,
    private data: DataService,
    private engine: EngineService,
    private ref: ElementRef,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.global.ready.background = true;
    this.events.addListener("EVENT_BACKGROUND", this, this._event_background);
    this.events.addListener("EVENT_BACKGROUND_PRELOAD", this, this._event_preload);
    this.events.addListener("EVENT_ELLIPSE_WAIT", this, this._event_ellipse_wait);
    this.events.addListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_BACKGROUND", this, this._event_background);
    this.events.removeListener("EVENT_BACKGROUND_PRELOAD", this, this._event_preload);
    this.events.removeListener("EVENT_ELLIPSE_WAIT", this, this._event_ellipse_wait);
    this.events.removeListener("EVENT_ELLIPSE_ENDING", this, this.animate);
    this.events.removeListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);
  }

  // preloading image & son
  _event_preload(a_params: { background: string/*, music?: string*/ }): void
  {
    //this.global.log("preload background " + a_params.background);

    this.preloaded = a_params.background;
    this.preload.src = this.data.getDataBgPath(this.preloaded);

    //if (a_params.music) this.audio.preloadBg(a_params.music);
  }

  // demande de changement de background
  _event_background(a_params: { background?: string, music?: string }): void
  {
    var _this = this;
    //this.global.log("EVENT_BACKGROUND " + a_params.background);

    // on note si on vient de passer en mode jeu
    var _ingamePrev = this.ingame;
    this.ingame = this.engine.running;

    var _backgroundPrev = this.background;

    // on ne fait rien s'il n'y a pas de changement
    var _changed = (a_params.background != _backgroundPrev) || (_ingamePrev != this.ingame);
    if (!_changed)
      return;

    // préparation du lancement de la musique au démarrage de l'animation
    this.music = (a_params.music != null && a_params.music.length > 0) ? a_params.music : null;

    // détection d'une variante de background de jeu impliquant un changement sans transition
    var _variant: boolean = false;
    if (this.background != null && a_params.background != null)
    {
      var _splitOld: Array<string> = this.background.split('_');
      var _splitNew: Array<string> = a_params.background.split('_');
      if (_splitOld[0] == _splitNew[0])
        _variant = true;
    }

    // si on efface le background, on nettoie tout et on arrête là
    if (a_params.background == null)
    {
      this.background = null;
      this.location = "";

      // sur changement d'écran on supprime tout le contenu
      gsap.set(this.ref.nativeElement, { backgroundImage: 'none' });
    }
    // si on est hors jeu ou que l'on vient juste de passer en jeu ou s'il s'agit d'une variante on le modifie immédiatemment
    else if (!this.ingame || _variant)
    {
      // changement de background effectif
      this.background = (a_params.background != null) ? a_params.background : null;
      this.location = "";

      var _path = this.data.getDataBgPath(a_params.background);
      this.imgRef.nativeElement.src = _path;

      //this.updateLocation(a_params.background);

      // en cas de variante, on passe à la suite
      if (this.engine.running)
        this.engine.next();
    }
    // sinon il s'agit d'un background de jeu
    else
    {
      this.next_background = a_params.background;

      // lancement de la musique s'il y en a une et qu'on est pas dans l'intro
      if (!this.global.intro)
        this.audio.playBg(this.music);

      // d'abord on signale que le background n'est plus prêt, afin de bloquer toute animation ultérieure
      if (this.global.ready.background)
      {
        this.global.ready.background = false;
        this.events.sendEvent("EVENT_BACKGROUND_READY");
      }

      // soit il y a une transition en cours et en profite
      if (this.global.transition)
      {
        // on affiche le fond et on attends qu'elle se termine pour lancer l'animation
        this.show();
        this.events.addListener("EVENT_TRANSITION_ENDING", this, this.animate);
      }
      // soit on utilise une ellipse
      else 
      {
        // et on attendra la fin de l'ellipse pour lancer l'animation
        this.events.addListener("EVENT_ELLIPSE_ENDING", this, this.animate);

        // s'il n'y a pas d'ellipse en cours
        if (!this.global.ellipse)
        {
          // on en lance une
          this.events.sendEvent("EVENT_ELLIPSE_SHOW", [{ show: true }]);
        }
        else
        {
          // sinon on passe directement à l'affichage
          this.show();
        }
      }
    }
  }

  // si on était en attente pour changer le background et que le talker est maintenant vide
  _event_ellipse_wait()
  {
    // on affiche le nouveau background
    this.show();

    // et on signale qu'on peut terminer l'ellipse
    this.events.sendEvent("EVENT_ELLIPSE_END");
  }

  // affichage du nouveau background
  show(): void
  {
    var _newBackground = this.next_background;
    if (_newBackground == null) return;

    // changement de background effectif
    this.next_background = null;
    this.background = _newBackground;
    this.location = "";

    gsap.set(this.ref.nativeElement, { left: 0 });

    this.imgRef.nativeElement.src = this.data.getDataBgPath(_newBackground);
  }

  // animation d'apparition éventuelle
  animate(): void
  {
    this.events.removeListener("EVENT_TRANSITION_ENDING", this, this.animate);
    this.events.removeListener("EVENT_ELLIPSE_ENDING", this, this.animate);
    var _this = this;

    let _tl = gsap.timeline().addLabel("start");

    // pas la peine d'avoir un framerate élevé
    //var _previousFPS = TweenLite.ticker.fps();
    //TweenLite.ticker.fps(24);


    // d'abord scrolling
    var _scrolling: any = {
      left: -384,
      ease: "power2.out",
      duration: this.global.BG_SCROLL_DURATION
    }

    // en cas d'intro, le scrolling est plus lent et plus doux
    var _isIntro: boolean = this.global.intro;
    if (_isIntro)
      _scrolling.duration = this.global.INTRO_OFFICE_SCROLL_DURATION;
    else
      _scrolling.delay = this.global.BG_SCROLL_DELAY;

    _tl.to(this.ref.nativeElement, _scrolling);


    // en cas d'intro, l'affichage du lieu intervient tout à la fin
    var _locationStart = this.global.BG_SCROLL_DELAY + this.global.BG_SCROLL_DURATION + this.global.BG_LETTERS_DELAY;
    if (_isIntro)
      _locationStart = this.global.INTRO_OFFICE_DURATION;

    // puis écriture du nom du lieu lettre par lettre
    var _base: string = (this.background != null) ? this.background.toLowerCase().split("_")[0] : "";
    var _location = this.data.translate("ui.title." + _base, "");

    var _writer = { letter: 0, progress: 0 };
    _tl.to(_writer, {
      duration: (this.global.BG_SCROLL_DURATION - this.global.BG_SCROLL_DELAY - this.global.BG_LETTERS_DELAY),
      progress: _location.length, ease: "linear",
      onUpdate: function ()
      {
        while (_writer.letter < _writer.progress)
        {
          _this.ngZone.run(() =>
          {
            _writer.letter++;
            _this.location = _location.substring(0, _writer.letter);
          });
        }
      }
    }, "start+=" + _locationStart);

    // puis on passe à la suite du scenario
    _tl.call(
      function ()
      {
        _this.ngZone.run(() =>
        {
          // on remet le framerate
          //TweenLite.ticker.fps(_previousFPS);

          //console.log("background ok");

          // on signale que le background est prêt
          _this.global.ready.background = true;
          _this.events.sendEvent("EVENT_BACKGROUND_READY");

          // on passe à la suite si on est pas en mode intro
          if (!_isIntro)
            _this.engine.next();
          // ou on lance la musique du à la fin de l'intro
          else
            _this.audio.playBg(_this.music);

        });
      });
  }

  _event_transition_ended(): void
  {
  }

}
