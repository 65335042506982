import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { AudioService } from 'src/app/services/audio.service';
import { EngineService } from 'src/app/services/engine.service';
import { EventsService } from 'src/app/services/events.service';
import { DataService } from 'src/app/services/data.service';

import { Global, Status } from 'src/app/global';

import gsap from 'gsap';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit
{
  // type de popup
  @Input('type') type = 'restart';

  text: string = "";
  confirm: string = "";
  cancel: string = "";

  constructor(
    private global: Global,
    private data: DataService,
    private audio: AudioService,
    private events: EventsService,
    private engine: EngineService,
    private ref: ElementRef,
    private ngZone: NgZone)
  {

  }

  ngOnInit(): void
  {
    if (this.type == 'restart')
    {
      this.text = "ui.restart.confirm.text";
      this.confirm = "ui.restart.confirm.btn";
      this.cancel = "ui.restart.cancel";
    }
    else if (this.type == 'connect')
    {
      this.text = "ui.connexion.text.failed";
      this.confirm = "";
      this.cancel = "ui.connexion.text.failed.btn";
    }
    else if (this.type == 'consent')
    {
      this.text = "ui.connexion.text.data.failed";
      this.confirm = "";
      this.cancel = "ui.connexion.text.failed.btn";
    }
  }

  ngAfterViewInit(): void
  {
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.UI_FADE_DURATION,
        autoAlpha: 1
      });
  }

  ngOnDestroy(): void
  {
    gsap.killTweensOf(this.ref.nativeElement);
  }

  onButton(a_confirm: boolean): void
  {
    var _this = this;

    if (a_confirm && _this.type == "restart")
    {
      // pas besoin d'anim de disparition s'il s'agit d'un reset
      _this.data.resetUser();

      if (this.global.status == Status.home)
        _this.global.gotoStatus(Status.intro);
      else
        _this.global.gotoStatus(Status.home);
    }
    else
    {
      gsap.to(this.ref.nativeElement,
        {
          duration: this.global.UI_FADE_DURATION,
          autoAlpha: 0,
          onComplete: function () 
          {
            _this.events.sendEvent("EVENT_CONFIRM_SHOW");
          }
        });
    }
  }
}
