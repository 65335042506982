import { Injectable } from '@angular/core';
import { EventsService } from './services/events.service';
import { environment } from 'src/environments/environment';

/**
 * Singleton global à l'application
 * Permet de gérer les propriétés globales de l'appli
 */

////////////////////////////////////////////////////////////////////////
// utilitaires

export interface Rect
{
    x: number;
    y: number;
    w: number;
    h: number;
};

////////////////////////////////////////////////////////////////////////
// enums

export enum Status
{
    none = 0,
    loading = 1,
    login = 2,
    warning = 3,
    logo = 4,
    home = 5,
    intro = 6,
    playing = 7,
    outro = 8,
    ending = 9
};


@Injectable()
export class Global
{
    SAFEID = "formasup";

    // version du jeu
    VERSION = "1.0.1";

    ////////////////////////////////////////////////////////////////////////
    // const

    GAME_WIDTH = 1920;
    GAME_HEIGHT = 1080;
    BG_OFFSET = 384;

    // animations
    TRANSITION_DURATION = 1.0;
    UI_FADE_DURATION = 0.3;

    BUTTON_SCALE_DURATION = 0.15;
    BUTTON_SCALE_DOWN = 0.95;
    BUTTON_SCALE_UP = 1.03;
    BUTTON_SCALE_UP_LARGE = 1.01;

    SPEECH_SCALE_DURATION = 0.15;
    SPEECH_SCALE_VALUE = 0.97;

    UI_CONNECT_DELAY = 0.1;

    UI_TITLE_DURATION = 1.0;
    UI_TITLE_WAIT = 0.5;
    UI_TITLE_DELAY = 0.1;


    MENU_SLIDE_DURATION = 0.3;

    GAME_WORD_DURATION = 0.08;

    BG_SCROLL_DURATION = 1.0;
    BG_SCROLL_DELAY = 0.2;
    BG_WAIT_DURATION = 1.0;
    BG_FADE_DURATION = 0.25;
    BG_LETTERS_DELAY = 0.3;


    // paramétrage commun pour l'intro/outro
    INTRO_TEXT_FADEIN_DURATION = 0.5;
    INTRO_TEXT_FADEOUT_DURATION = 0.5;
    OUTRO_TEXT_FADEIN_DURATION = 1.0;
    OUTRO_TEXT_FADEOUT_DURATION = 1.0;
    INTRO_OUTRO_CROSSFADE_DURATION = 0.5;
    

    // durée d'affichage des slides intro, total=36s

    // durée du fondu au noir pour l'intro
    INTRO_FADE_DURATION = 1.0;
    
    // slide intro/building
    INTRO_BUILDING_DURATION = 15;
    INTRO_BUILDING_SCROLL_START = 2.0;
    INTRO_BUILDING_SCROLL_DURATION = 6.5;
    INTRO_BUILDING_TEXT0_START = 0.5;
    INTRO_BUILDING_TEXT0_WAIT = 5.5;
    
    // slide intro/polaroid
    INTRO_POLAROID_DURATION = 9.7;
    INTRO_POLAROID_TEXT_START = 2.0;

    // slide intro/office
    INTRO_OFFICE_DURATION = 10.0;
    INTRO_OFFICE_SCROLL_DURATION = 4.0;
    INTRO_OFFICE_TEXT_START = 1.0;


    // durée d'affichage des slides outro, total=23s

    // durée du fondu au noir pour l'outro
    OUTRO_FADEIN_DURATION = 2.5;
    OUTRO_FADEOUT_DURATION = 2.5;
    
    // slide outro/polaroid
    OUTRO_POLAROID_DURATION = 8.5;
    OUTRO_POLAROID_SCROLL_DURATION = 5.5;

    // slide outro/building
    OUTRO_BUILDING_DURATION = 20;
    OUTRO_BUILDING_SCROLL_START = 1.0;
    OUTRO_BUILDING_SCROLL_DURATION = 14.5;
    OUTRO_BUILDING_TEXT1_START = 13.5;
    OUTRO_BUILDING_TEXT0_START = 2.5;
    OUTRO_BUILDING_TEXT0_WAIT = 6.75;


    // paramétrage affichage du texte commun aux deux slides
    INTRO_WORD_DURATION = 0.08;


    PROP_SCALE_DURATION = 0.1;


    TALKER_FADE_DURATION = 0.3;
    TALKER_SLIDE_DURATION = 0.7;
    PLAYER_DURATION = 0.5;
    ACTION_DURATION = 0.3;
    PLAYER_CHOICE_SCALE_UP = 1.01;

    ELLIPSE_TIME_FADE = 1.0;
    ELLIPSE_TIME_REVEAL = 0.5;
    ELLIPSE_TIME_WAIT_DEFAULT = 5;

    
    LOGO_WAIT = 2.0;


    PATH_BG = "bg/";
    PATH_IMG = "img/";
    PATH_AUDIO_FX = "audio/fx/";
    PATH_AUDIO_BG = "audio/bg/";
    PATH_TALKER = "talker/";
    PATH_PROP = "prop/";

    ////////////////////////////////////////////////////////////////////////
    // vars

    // état actuel du jeu
    // status indique l'état dans lequel se trouve l'app
    // cet état permet de définir sur quel écran on se trouve
    // un écran est constitué d'un composant principal
    // et d'éventuels overlays liés au status
    status: Status = Status.none;

    // permet de savoir s'il y a une transition ou une ellipse en cours
    transition: boolean = false;
    ellipse: boolean = false;

    // permet de savoir si on est en mode intro
    intro: boolean = false;

    // permet de personnaliser la durée de la transition, pour l'intro notamment
    overrideTransitionDuration: number | null = null;

    // flags pour la mise en attente entre les composants
    ready: { background: boolean, talker: boolean, player: boolean } = { background: true, talker: true, player: true };

    // zone de jeu
    top = 0;
    left = 0;
    scale = 1;

    // configuration de la racine d'affichage
    root = "global-root";

    // indique si le device est en mode portrait
    portrait = false;

    // mode dévelopement ou test
    dev: boolean = false;
    testing: boolean = false;

    // url webservice pour dev
    URL_WEBSERVICE_DEV:string = "https://formasup.dowino.com";

    ////////////////////////////////////////////////////////////////////////
    // public

    // changement d'état du jeu 
    gotoStatus(a_status: Status): void
    {
        // si on est sur le même status, on ne change rien
        if (a_status == this.status)
            return;

        this.status = a_status;

        // on note qu'on est passé par l'intro, ça permettra de la terminer dans l'état playing
        if (this.status == Status.intro)
            this.intro = true;

        this.events.sendEvent("EVENT_GOTO_STATUS");
    }


    ////////////////////////////////////////////////////////////////////////
    // utils

    cleanHtml(a_text: string): string
    {
        // non breaking-space + retour à la ligne
        return a_text.replace(/\n/g, "<br/>").
            replace(/\s\?/g, "&nbsp;?").
            trim();
    }

    ////////////////////////////////////////////////////////////////////////
    // traces

    public log(a_info: string, a_msg: any = null): void
    {
        /*if (a_msg == null)
            console.log(a_info);
        else
            console.log(a_info, a_msg);*/
    }

    public warn(a_msg: string): void
    {
        console.warn("warning: " + a_msg);
    }

    public error(a_msg: string): void
    {
        console.error(a_msg);
    }

    public alert(a_msg: string): void
    {
        alert(a_msg);
    }


    ////////////////////////////////////////////////////////////////////////
    // core

    constructor(public events: EventsService)
    {
        // mode développement si on lance en localhost
        this.dev = window.location.href.indexOf("localhost") != -1;
        this.testing = this.dev || window.location.href.indexOf("formasup.dowino.com") != -1;
        //this.log("dev mode=", this.dev);
    }

    ngOnDestroy()
    {
    }

    // traitement du resize
    onResize()
    {
        var _fullheight = document.body.clientHeight;
        var _fullwidth = document.body.clientWidth;
        this.portrait = _fullheight > _fullwidth;

        this.top = 0;
        this.left = 0;
        var width = _fullwidth;
        var height = _fullheight;

        // capping
        if (width > this.GAME_WIDTH) width = this.GAME_WIDTH;
        if (height > this.GAME_HEIGHT) height = this.GAME_HEIGHT;

        // puis on respecte le ratio
        if ((width * this.GAME_HEIGHT) > (this.GAME_WIDTH * height))
        {
            // bandes sur les côtés
            width = height * this.GAME_WIDTH / this.GAME_HEIGHT;
            //Global.log("width = "+_width);
        }
        else
        {
            // bandes au dessus
            height = width * this.GAME_HEIGHT / this.GAME_WIDTH;
        }

        this.left = (_fullwidth - width) * 0.5;
        this.top = (_fullheight - height) * 0.5;

        this.scale = width / this.GAME_WIDTH;

        //console.log(this.left+" "+this.top+" "+this.width+" "+this.height);

        // mise à jour du mode d'affichage si besoin
        this.root = "global-root";

        // notification du resize
        this.events.sendEvent('EVENT_SCREEN_RESIZE');
    }
}
