import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { Global } from 'src/app/global';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';

/**
 * Traitement du stockage local et distant
 */

@Injectable({
  providedIn: 'root'
})
export class StorageService
{

  ///////////////////////////////////////////////////////////////////////////////////
  // public

  // code d'accès au jeu
  uid: string | null = null;

  // warning éventuel à afficher au lancement du jeu
  warning: string | null = null;

  constructor(private global: Global,
    private events: EventsService,
    private http: HttpClient) { }


  // récupère le chemin d'une data
  public getDataPath(a_file: string): string
  {
    // chemin par défaut
    var _data_path = "data/" + a_file;
    //Global.log(a_file +" > "+_data_path);
    return _data_path;
  }

  // appel d'un webservice avec éventuels paramètres
  public requestWebService(a_service: string, a_handler: any, a_params:any=null): void
  {
    this._requestWebService(a_service, a_handler, a_params);
  }

  // appel du webservice pour les données et la vérification de l'accès
  public loadDataService(a_service: string, a_handler: any): void
  {
    this._loadJson(a_service, a_handler, true);
  }

  // charge un fichier du dossier data
  public loadDataFile(a_id: string, a_handler: any): void
  {
    //this.global.log("load file", a_file);
    this._loadJson(a_id, a_handler, false);
  }


  // sauvegarde les données utilisateurs
  public saveUserData(a_uid: string, a_data: string): void
  {
    this._requestWebService("saveProgression", null, {
      userUID: a_uid,
      progression: a_data
    });
  }


  /////////////////////////////////////////////////////////////////////////
  // privé

  // chargement de données json, en direct ou par web service
  private _requestWebService(a_id: string, a_handler: any, a_params:any=null): void
  {
    var _storage = this;
    var _global = this.global;

    this._loadService(a_id,
      function (a_data: any)
      {
        a_data.json = null;

        // on vérifie la validité du json
        try
        {
          a_data.json = JSON.parse(a_data.content);

          // cas d'un webservice qui renvoie le résultat de la requête
          if (a_data.json.success != null)
          {
            // si tout s'est bien passé le json est dans data
            if (a_data.json.success)
              a_data.json = a_data.json.data;
            else
              a_data.json = null;
          }
        }
        catch (e)
        {
          _global.error("Données serveur " + a_id + " invalides");
          a_data.content = null;
        }

        if (a_handler!=null)
          a_handler.apply(_storage, [a_data]);
      },
    a_params);
  }

  // chargement de données json, en direct ou par web service
  private _loadJson(a_id: string, a_handler: any, a_service: boolean, a_params:any=null): void
  {
    var _storage = this;
    var _global = this.global;

    // on essaye d'abord de lire dans le dossier data
    var _short_filename: string = a_id.split("/").pop() as string;
    var _fct = a_service ? _storage._loadService.bind(this) : _storage._loadFile.bind(this);
    _fct(_short_filename,
      function (a_data: any)
      {
        a_data.json = null;
        //_storage.global.log("load "+a_file+" complete");
        //Global.log(a_stored);

        // dans le cas d'un json on vérifie la validité
        try
        {
          a_data.json = JSON.parse(a_data.content);

          // cas d'un webservice qui renvoie le résultat de la requête
          if (a_data.json.success != null)
          {
            // si tout s'est bien passé le json est dans data
            if (a_data.json.success)
              a_data.json = a_data.json.data;
            // sinon on affichera le message d'erreur
            else
              _storage.warning = a_data.json.data.errorMessage;
          }
        }
        catch (e)
        {
          _global.error("Fichier " + a_id + " invalide");
          a_data.content = null;
        }

        a_handler.apply(_storage, [a_data]);
      },
    a_params);
  }

  // chargement d'un fichier sur le serveur
  private _loadFile(a_data: string, a_handler: any, a_params:any=null): void
  {
    var _result: any = {
      file: a_data,
      content: null
    };

    var _url: string = "data/" + a_data + "?r=" + Math.random();
    this.http.get(_url, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    ).subscribe(a_content =>
    {
      _result.content = a_content;
      a_handler(_result);
    });
  }


  // récupération d'une donnée sur le serveur par l'intermediaire d'un webservice
  private _loadService(a_service: string, a_handler: any, a_params:any=null): void
  {
    var _result: any = {
      file: a_service,
      content: null
    };

    if (a_params==null) a_params = {};
    a_params["sessionUID"] = this.uid;

    var _url_base: string = (this.global.dev ? this.global.URL_WEBSERVICE_DEV : "");
    var _url: string = _url_base + "/service/" + a_service;
    this.http.post(_url, a_params, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    ).subscribe(a_content =>
    {
      _result.content = a_content;
      a_handler(_result);
    });
  }

  private handleError(error: HttpErrorResponse)
  {
    if (error.status === 0)
    {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }
    else
    {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
