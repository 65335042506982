import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';
import { Global, Status } from 'src/app/global';

@Component({
  selector: 'app-endingdemo',
  templateUrl: './endingdemo.component.html',
  styleUrls: ['./endingdemo.component.scss']
})
export class EndingdemoComponent implements OnInit {

  message: string | null = "";

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private storage: StorageService) { }

  ngOnInit(): void
  {
    this.events.sendEvent("EVENT_TRANSITION_END");

    this.message = this.data.translate("ui.demo.ending.text");

    console.log(this.message)
  }


  
  onHome()
  {
    this.global.gotoStatus(Status.home);
  }

}
