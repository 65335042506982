import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { Global } from 'src/app/global';
import { StorageService } from './storage.service';

/**
 * Gestion des données de jeu
 */

@Injectable({
  providedIn: 'root'
})
export class DataService
{
  // données de jeu
  public wordings: any = {};
  public notions: any = {};
  public graph: any = {};
  public session: any = {};
  public scene: { bg?: any, talker?: any, prop?: any } = {};

  // données à sauvegarder
  public user: any = {};
  public uid: string = "";
  public firstname: string = "";
  public lastname: string = "";

  constructor(private global: Global,
    private events: EventsService,
    private storage: StorageService) { }

  //////////////////////////////////////////////////////////////////////////////////////////
  // public
  init()
  {
    // d'abord chargement du getData
    // en dev, on récupère directement le fichier local
    /*if (this.global.dev)
      this.storage.loadDataFile("gameData.json", this._game_loaded.bind(this));
    else*/
    this.storage.loadDataService("getData", this._game_loaded.bind(this));
  }


  // réinitialise les données de jeu
  resetUser(): void
  {
    // base du user
    var _user: any = {}

    // récupération d'éventuelles données précédentes
    if (this.user.audio != null)
      _user.audio = this.user.audio;

    // et mise à niveau des données manquantes
    this.user = _user;
    this._checkUser();

    this.save();
  }

  initUserData(a_data: any): void
  {
    // récupération des infos du joueur et de sa progression
    this.uid = a_data.uid ? a_data.uid : "";
    this.firstname = a_data.firstname ? a_data.firstname : "";
    this.lastname = a_data.lastname ? a_data.lastname : "";
    this.user = a_data.progression;

    // prise en charge d'une éventuelle erreur
    var _reset_user = false;
    if (this.user == null)
    {
      //this.global.error("Erreur sur les données du joueur");
      _reset_user = true;
      this.user = {};
    }

    // création et sauvegarde des données user de base s'il n'existe pas
    if (_reset_user)
      this.resetUser();

    this._checkUser();

    this.global.log("load user data " + JSON.stringify(this.user));
  }


  // sauve la progression du joueur
  save(): void
  {
    var _user_json = JSON.stringify(this.user);
    this.global.log("save user data " + _user_json);

    this.storage.saveUserData(this.uid, _user_json);
  };

  // wording
  translate(a_key: string, a_default: string | null = null): string
  {
    if (typeof this.wordings[a_key] == "undefined")
      return (a_default != null) ? a_default : "?????";

    var _str = this.wordings[a_key];

    // non breaking-space
    _str = _str.replace(/\s\?/g, "&nbsp;?").
      trim();

    return _str;
  }


  // récupère le chemin d'un arrière plan en tenant compte des variantes blanche/formasup
  public getDataBgPath(a_file: string): string
  {
    var _path = this.global.PATH_BG + a_file;
    var _formasup = this.session.decors == 1;
    if (_formasup)
    {
      // si on en mode formasup, on regarde s'il y a bien une version formasup
      if (this.scene.bg[a_file + "_formasup"] != null)
        _path += "_formasup";
    }
    _path += ".jpg";

    // chemin par défaut
    //this.global.log(a_file +" > "+_path);
    return this.storage.getDataPath(_path);
  }

  // récupère le chemin d'un perso en tenant compte des variantes blanche/formasup
  public getDataTalkerPath(a_file: string): string
  {
    var _path = this.global.PATH_TALKER + a_file;
    var _formasup = this.session.decors == 1;
    if (_formasup)
    {
      // si on en mode formasup, on regarde s'il y a bien une version formasup
      if (this.scene.talker[a_file + "_formasup"] != null)
        _path += "_formasup";
    }
    _path += ".png";

    // chemin par défaut
    //this.global.log(a_file +" > "+_path);
    return this.storage.getDataPath(_path);
  }



  //////////////////////////////////////////////////////////////////////////////////////////
  // privé


  _game_loaded(a_loaded: any)
  {
    //this.global.log(a_loaded);
    if (a_loaded.json != null)
    {
      this.wordings = a_loaded.json.wordings;
      this.notions = a_loaded.json.notions;
      this.session = a_loaded.json.session;
    }

    // puis chargement du gamePlay
    this.storage.loadDataFile("graphData.json", this._graph_loaded.bind(this));
  }

  _graph_loaded(a_loaded: any)
  {
    //this.global.log(a_loaded);
    if (a_loaded.json != null)
    {
      this.graph = a_loaded.json.graph;
      //console.log(this.graph);
    }

    // puis chargement du gamePlay
    this.storage.loadDataFile("sceneData.json", this._scene_loaded.bind(this));
  }

  _scene_loaded(a_loaded: any)
  {
    //this.global.log(a_loaded);
    if (a_loaded.json != null)
    {
      this.scene = a_loaded.json;

      // pour toutes les positions on intégère l'offset
      /*for (var k in this.scene.talker)
        this.scene.talker[k].x -= this.global.BG_OFFSET;
      for (var k in this.scene.prop)
        this.scene.prop[k].x -= this.global.BG_OFFSET;*/

      //console.log(this.scene);
    }

    this.events.sendEvent("EVENT_DATA_READY");
  }

  _checkUser(): void
  {
    // valeurs par défaut
    if (this.user.audio == null)
      this.user.audio = 1;
    if (this.user.progress == null)
      this.user.progress = {};
  }
}
