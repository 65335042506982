import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';

import { Global, Status } from 'src/app/global';

import gsap from 'gsap';
import { AudioService } from 'src/app/services/audio.service';
import { EngineService } from 'src/app/services/engine.service';
import * as OverlayScrollbars from 'overlayscrollbars';

/**
 * Ecran de chargement et maintenant de connexion
 * 
 */

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit
{
  @ViewChild('icon') iconRef!: ElementRef;
  @ViewChild('text') textRef!: ElementRef;

  preload: number = 0;

  constructor(
    private global: Global,
    private events: EventsService,
    private storage: StorageService,
    private engine: EngineService,
    private data: DataService,
    private audio: AudioService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void
  {

    this.events.addListener("EVENT_DATA_READY", this, this._event_data_ready);
    this.events.addListener("EVENT_AUDIO_READY", this, this._event_audio_ready);

    //gsap.registerPlugin(TextPlugin);

    // set the default overwrite mode to "auto", like it was in GSAP 1.x/2.x
    gsap.defaults({ ease: "none", overwrite: "auto" });

    // nullTargetWarn permet de supprimer les warnings sur les boutons supprimés dans leur onComplete
    gsap.config({ nullTargetWarn: false });

    // framerate à 30
    gsap.ticker.fps(30);

    // configuration des scrollbars: theme light customisé
    var _options: OverlayScrollbars.Options = OverlayScrollbars.defaultOptions();
    _options.className = "os-theme-light";
    OverlayScrollbars.defaultOptions(_options);

    // récupération de l'uid dans l'url
    const params = new URLSearchParams(location.search);
    this.storage.uid = params.get("uid");

    // appel du getdata
    this.data.init();
  }
  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_DATA_READY", this, this._event_data_ready);
    this.events.removeListener("EVENT_AUDIO_READY", this, this._event_audio_ready);
  }

  _event_data_ready()
  {
    this.audio.init();
  }

  _event_audio_ready()
  {
    // preloading audio
    this.audio.preloadBg("intro", false);
    this.audio.preloadBg("outro", false);
    this.audio.preloadBg("ui");

    // on peut avoir un message d'erreur sur le contexte, ce n'est pas grave le preloading semble marcher quand même
    for (var _id in this.data.graph)
    {
      // récupération du node d'entrée
      var _node: any = this.data.graph[_id];
      if (_node.type == "Background" && _node.soundFile != null && _node.soundFile.length > 0)
        this.audio.preloadBg(_node.soundFile);
    }



    // preloading
    // TODO: voir si une PWA avec un manifest ne serait pas plus adapté pour la gestion du cache
    var _images: Array<string> = [];

    var _bg: Array<string> = [];
    /*for (var _id in this.data.graph)
    {
      // récupération du node d'entrée
      if (this.data.graph[_id].type == "Background")
        _bg.push(this.data.graph[_id].background.toLowerCase() + ".jpg");
    }*/

    _bg.map((a_image) => { _images.push(this.storage.getDataPath(this.global.PATH_BG + a_image)); });

    // preload static
    [
      "answer-a.png",
      "answer-b.png",
      "answer-c.png",
      "bg-blue.png",
      "bg-res.png",
      "btn-action.png",
      "btn-back.png",
      "btn-burger.png",
      "btn-close.png",
      "btn-next.png",
      "btn-no.png",
      "btn-off.png",
      "btn-on.png",
      "btn-quick.png",
      "btn-res-large.png",
      "btn-yellow-large.png",
      "btn-yellow-small.png",
      "btn-yellow.png",
      "btn-yes.png",
      "icon-audio.png",
      "icon-fullscreen.png",
      "loading.png",
      "location.png",
      "logo.png",
      "res-selected.png",
      "speech.png",
      "survey-1.png",
      "survey-2.png",
      "survey-3.png",
      "survey-4.png",
      "survey-5.png",
      "survey-6.png",
      "survey-7.png",
      "survey-8.png",
      "title.png",
      "txt-menu.png",
      "input-text.png",
      "check-off.png",
      "check-on.png"
    ].map((a_image) =>
    {
      _images.push(this.storage.getDataPath(this.global.PATH_IMG + a_image));
    });

    this.preload = _images.length;
    var _this = this;
    _images.map((a_image) =>
    {
      //_this.global.log("preload", a_image);

      var _image = new Image();
      _image.onload = _this._image_loaded.bind(this);
      _image.src = a_image;

      // on stocke les images en mémoire
      /*var _splitted = a_image.split('/');
      var _filename = _splitted[_splitted.length-1];
      _this.global.cache.images[_filename] = _image;*/
    });

    //this.global.log("images", _images);
    if (this.preload == 0)
      this._ready();
  }

  _image_loaded()
  {
    var _this = this;
    this.ngZone.run(() =>
    {
      _this.preload--;
      //_this.global.log("load complete", _this.preload);
      if (_this.preload == 0)
        this._ready();
    });
  }


  // une fois que tout est prêt
  private _ready(): void
  {
    //this.global.log("loading complete");

    // si le code d'accès n'est pas bon on renvoie sur un écran d'information
    if (this.storage.warning != null)
      this.global.gotoStatus(Status.warning);
    // sinon on passe à l'écran de login
    else
      this.global.gotoStatus(Status.login);
  }
}
