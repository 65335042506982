import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AudioService } from 'src/app/services/audio.service';

import { Global } from 'src/app/global';

import gsap from 'gsap';
import { EventsService } from 'src/app/services/events.service';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { StorageService } from 'src/app/services/storage.service';

/**
 * Ecran d'affichage des ressources
 * 
 * Affiché soit depuis le menu
 * Soit directement sous forme de popup
 */

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit
{

  @Input('popup') popup: boolean = false;
  @ViewChild('scrollbars') scrollbars!:OverlayScrollbarsComponent;

  notions: Array<{ id: string, txt: string }> = []

  selected: string = "";

  title: string = "";
  text: string = "";
  links: Array<any> = [];

  constructor(
    private global: Global,
    private data: DataService,
    private storage: StorageService,
    private audio: AudioService,
    private events: EventsService,
    private ref: ElementRef
  )
  { }

  ngOnInit(): void
  {
    this.notions = [];
    for (var key in this.data.notions)
    {
      var _index: number = parseInt(key.substring(1));
      this.notions.push({ id: key, txt: this.data.translate("ui.ressources.n" + _index) });
    }
    this._select(this.notions[0].id, 0);

    this.events.addListener("EVENT_RES_POPUP_CLOSE", this, this._event_res_popup_close);
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_RES_POPUP_CLOSE", this, this._event_res_popup_close);
  }

  ngAfterViewInit(): void
  {
    if (this.popup)
      gsap.to(this.ref.nativeElement, { duration: this.global.UI_FADE_DURATION, autoAlpha: 1 });
    else
      gsap.set(this.ref.nativeElement, { autoAlpha: 1 });
  }

  onClick(a_id: string, a_index:number)
  {
    this.audio.playButton();
    this._select(a_id, a_index);
  }

  // click sur background lorsqu'on est en mode popup
  onBackground()
  {
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "" }])
    this._event_res_popup_close();
  }

  _select(a_id: string, a_index:number)
  {
    // notification serveur
    this.storage.requestWebService("saveNotionViewed", null, {
      userUID: this.data.uid,
      notion: (a_index+1)
    })

    this.selected = a_id;
    var _info: any = this.data.notions[a_id];
    this.title = this.global.cleanHtml(_info.title);
    this.text = this.global.cleanHtml(_info.description);
    this.links = _info.links;
    this.scrollbars?.osInstance()?.scroll({ y : 0 });
  }

  _event_res_popup_close()
  {
    // fadeout
    var _this = this;
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.UI_FADE_DURATION,
        autoAlpha: 0,
        onComplete: function ()
        {
          _this.events.sendEvent("EVENT_RES_POPUP_SHOW", [{ show: false }]);
        }
      });
  }
}
