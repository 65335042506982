import { Component, ElementRef, HostBinding, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { EngineService } from 'src/app/services/engine.service';
import { StorageService } from 'src/app/services/storage.service';
import { DataService } from 'src/app/services/data.service';

import { Global, Rect } from 'src/app/global';

import gsap from 'gsap';

/**
 * Affichage d'un personnage
 * 
 * N'est affiché que s'il n'y a pas une animation background en cours
 * 
 */


@Component({
  selector: 'app-talker',
  templateUrl: './talker.component.html',
  styleUrls: ['./talker.component.scss']
})
export class TalkerComponent implements OnInit
{
  talker: string = "";

  name: string = "";
  color: string = "";

  speed: number = 1;

  @ViewChild('gradient') gradient!: ElementRef;
  @ViewChild('avatar') avatar!: ElementRef;
  @ViewChild('speech') speech!: ElementRef;
  @ViewChild('content') content!: ElementRef;

  @HostBinding('class') classname = '';

  next_talker: string | null = null;
  next_message: string | null = null;

  next: boolean = false;

  timeline: any = null;


  //word: number = -1;
  //progress: number = 0;

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private storage: StorageService,
    private engine: EngineService,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.global.ready.talker = true;
    this.classname = "noevents";
    this.events.addListener("EVENT_TALKER", this, this._event_talker);
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_TALKER", this, this._event_talker);
  }

  _event_talker(a_params?: { talker: string, content: string, next?: boolean, speed?: number }): void
  {
    this.next = (a_params != null) ? (a_params.next != null) : false;
    this.next_talker = (a_params != null) ? a_params.talker : "";
    this.next_message = (a_params != null) ? a_params.content : "";
    this.speed = (a_params != null && a_params.speed != null) ? a_params.speed : 1;


    // on met en attente ce qui dépend du talker pour l'affichage
    this.global.ready.talker = false;
    this.events.sendEvent("EVENT_TALKER_READY");

    // on lance l'affichage que si le background a bien été affiché avant
    this._updateDisplay(true);
  }

  // on ne lance l'animation que si l'animation sur la background est terminée
  _updateDisplay(a_event: boolean): void
  {
    var _this = this;
    var _oldTalker = this.talker;
    var _newTalker = (this.next_talker == "empty" || this.next_talker == null) ? "" : this.next_talker;
    this.next_talker = null;

    var _words: Array<string> = [];
    if (this.next_message != null && this.next_message.length > 0)
    {
      _words = this.next_message.split(' ');
      this.content.nativeElement.innerText = "";
      //this.message = "";
    }

    this.next_message = null;

    //this.global.log("talker " +_newTalker);
    var _tl = gsap.timeline();
    this.timeline = _tl;

    // changement d'état
    // animation seulement si le type de visuel a changé
    var _sameTalker = _oldTalker == _newTalker;
    if (!_sameTalker)
    {
      this.talker = _newTalker;

      // récupération des infos de positionnement
      var _rect: Rect = this.data.scene.talker[this.talker];

      // on fait d'abord disparaitre l'ancien perso s'il y en a un
      if (_oldTalker.length > 0)
      {
        _tl.to(this.speech.nativeElement, { duration: this.global.TALKER_FADE_DURATION, ease: "power2.in", autoAlpha: 0 });
        _tl.to(this.avatar.nativeElement, { left: this.global.GAME_WIDTH, duration: this.global.TALKER_SLIDE_DURATION, ease: "power2.in" });
        _tl.to(this.gradient.nativeElement, { duration: this.global.TALKER_FADE_DURATION, ease: "power2.in", autoAlpha: 0 }, "<");
      }

      // puis on lance l'animation d'affichage avec le son
      if (this.talker.length > 0)
      {
        // activation du click si on est bien en phase de dialogue
        this.classname = "";

        _tl.call(function ()
        {
          _this.ngZone.run(() =>
          {
            // nom et couleur du perso
            _this.name = _this.data.translate("ui.name." + _this.talker.split("_")[0]);
            _this.color = _this.data.translate("ui.color." + _this.talker.split("_")[0]);
          })
        });

        // apparition de l'avatar
        var _url_talker = 'url("' + this.data.getDataTalkerPath(this.talker) + '")';
        //console.log(_url_talker);

        _tl.set(this.avatar.nativeElement, {
          left: this.global.GAME_WIDTH, top: _rect.y, width: _rect.w, height: _rect.h,
          backgroundImage: _url_talker
        });
        _tl.to(this.avatar.nativeElement, { left: (_rect.x - this.global.BG_OFFSET), duration: this.global.TALKER_SLIDE_DURATION, ease: "power2.out" });
        _tl.to(this.gradient.nativeElement, { duration: this.global.TALKER_SLIDE_DURATION, ease: "power2.out", autoAlpha: 1 }, "<");

        // apparition du texte
        _tl.to(this.speech.nativeElement, { duration: this.global.TALKER_FADE_DURATION, ease: "power2.out", autoAlpha: 1 });
      }
      else
      {
        // désactivation du click sinon
        this.classname = "noevents";
      }
    }

    _tl.addLabel("speech")

    // écriture mots à mot
    if (_words.length > 0)
    {
      var _writer = { element: this.content.nativeElement, words: _words, word: -1, progress: 0 };
      _tl.to(_writer, {
        duration: this.global.GAME_WORD_DURATION * _writer.words.length / this.speed,
        progress: _writer.words.length, ease: "linear",
        onUpdate: function ()
        {
          while (_writer.word < _writer.progress)
          {
            _writer.word++;
            var _message = _writer.words.slice(0, _writer.word + 1).join(' ');
            _writer.element.innerHTML = _message;
          }
        }
      });
    }

    // micro contraction si on reste sur le même talker
    if (_sameTalker)
    {
      _tl.to(this.speech.nativeElement, { duration: this.global.SPEECH_SCALE_DURATION, ease: "power2.in", scale: this.global.SPEECH_SCALE_VALUE }, "speech");
      _tl.to(this.speech.nativeElement, { duration: this.global.SPEECH_SCALE_DURATION, ease: "power2.out", scale: 1.0 }, "speech+="+this.global.SPEECH_SCALE_DURATION);
    }

    // puis on passe à la suite de l'affichage une fois que tout est affiché
    if (a_event)
    {
      _tl.call(function ()
      {
        _this.timeline = null;
        _this.ngZone.run(() =>
        {
          _this.global.ready.talker = true;
          _this.events.sendEvent("EVENT_TALKER_READY");
          if (_this.next)
            _this.engine.next();
        });
      });
    }
  }

  @HostListener('click', [])
  onClick()
  {
    // on termine l'animation en cours
    if (this.timeline != null)
    {
      this.timeline.totalProgress(1);
      this.timeline = null;
    }
  }
}
