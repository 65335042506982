import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Global, Status } from 'src/app/global';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';

import gsap from 'gsap';

/**
 * Ecran "Formasup présente"
 * 
 */

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit
{
  image: string = "";

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService) { }

  ngOnInit(): void
  {
    const _url_base: string = (this.global.dev ? (this.global.URL_WEBSERVICE_DEV + "/") : "");
    const _url_file: string = this.data.session?.logo_url ? this.data.session.logo_url : "data/img/logo.png";
    this.image = _url_base + _url_file;

    this.events.sendEvent("EVENT_TRANSITION_END");
  }

  ngAfterViewInit(): void
  {
    var _this = this;
    gsap.delayedCall(this.global.LOGO_WAIT, function ()
    {
      _this.global.gotoStatus(Status.home);
    });
  }

}
