<div class="bg"></div>

<div [class]="didascalie?'caption':'answer'" *ngIf="actions==null && content.length<=1">
    <div class="you" *ngIf="content.length>0">Vous</div>
    <div class="message" *ngIf="content.length>0" [innerHTML]="content[0]"></div>
    <app-button [inactive]='!interactable' key="ui.btn.suivant" (handler)="onNext()"></app-button>
</div>

<div [class]="'choices'+(interactable?' interactable':'')" *ngIf="actions==null && content.length>1">
    <div class="choice" *ngFor="let line of content; let index = index;" (click)="onChoiceClick($event, index)" (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave($event)"><div [class]="'answer'+index"></div><span [innerHTML]="line"></span></div>
</div>

<div class="actions" *ngIf="actions!=null">
    <div class="description" [innerHTML]="content[0]"></div>
    <div class="buttons">
        <app-button [inactive]='!interactable' *ngFor="let action of actions; let index = index;" type="action" [txt]="action"
            (handler)="onNext(index)"></app-button>
    </div>
</div>