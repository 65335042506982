import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoadingComponent } from './pages/loading/loading.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { PlayingComponent } from './pages/playing/playing.component';
import { LogoComponent } from './pages/logo/logo.component';
import { SlideshowComponent } from './pages/slideshow/slideshow.component';
import { EndingComponent } from './pages/ending/ending.component';
import { WarningComponent } from './pages/warning/warning.component';
import { EndingdemoComponent } from './pages/endingdemo/endingdemo.component';

const routes: Routes = [
  { path: '', redirectTo: 'loading', pathMatch: 'full' },
  { path: 'logo', component: LogoComponent },
  { path: 'warning', component: WarningComponent },
  { path: 'loading', component: LoadingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'intro', component: SlideshowComponent },
  { path: 'playing', component: PlayingComponent },
  { path: 'outro', component: SlideshowComponent },
  { path: 'ending', component: EndingComponent },
  { path: 'endingDemo', component: EndingdemoComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { initialNavigation: 'disabled' })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
