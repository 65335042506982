import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Global, Status } from './global';
import { AudioService } from './services/audio.service';
import { EventsService } from './services/events.service';

import gsap from 'gsap';
import { DataService } from './services/data.service';
import { StorageService } from './services/storage.service';


/**
 * Coeur de l'application
 * 
 */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  classnames = "";
  style = "";
  black0 = "";
  black1 = "";

  @ViewChild('quick', { read: ElementRef }) quickRef!: ElementRef;
  @ViewChild('burger', { read: ElementRef }) burgerRef!: ElementRef;

  // éléments en overlay
  ellipse: any | null = null;
  menu: boolean = false;
  confirm: string | null = null;
  burger: boolean = false;
  quick: boolean = false;

  respopup: boolean = false;
  infpopup: string|null = null;

  // gestion des écrans
  status: Status = Status.none;
  transition: Status = Status.none;

  // activité et temps de session
  activity_state: boolean = false;
  activity_timeout: any = null;
  session_timeout: any = null;

  constructor(
    private global: Global,
    private data: DataService,
    private storage: StorageService,
    private events: EventsService,
    private router: Router,
    private audio: AudioService,
    private ngZone: NgZone)
  {

    // init
    this.events.init(ngZone);

    this.activity_state = false;
    this.activity_timeout = null;
    document.addEventListener("mousemove", this._event_mouse_move.bind(this));

    this.events.addListener("EVENT_LOGIN_COMPLETE", this, this._event_login_complete);
    this.events.addListener('EVENT_SCREEN_RESIZE', this, this._event_screen_resize);

    this.events.addListener("EVENT_GOTO_STATUS", this, this._event_goto_status);
    this.events.addListener("EVENT_TRANSITION_SAME", this, this._event_transition_same);
    this.events.addListener("EVENT_TRANSITION_WAIT", this, this._event_transition_wait);
    this.events.addListener("EVENT_TRANSITION_ENDED", this, this._event_transition_end);
    this.events.addListener("EVENT_ELLIPSE_SHOW", this, this._event_ellipse_show);
    this.events.addListener("EVENT_MENU_SHOW", this, this._event_menu_show);
    this.events.addListener("EVENT_RES_POPUP_SHOW", this, this._event_res_popup_show);
    this.events.addListener("EVENT_INF_POPUP_SHOW", this, this._event_inf_popup_show);
    this.events.addListener("EVENT_CONFIRM_SHOW", this, this._event_confirm_show);
    this.events.addListener("EVENT_BURGER_HIDE", this, this._event_burger_hide);
    this.events.addListener("EVENT_QUICK_HIDE", this, this._event_quick_hide);

    this.global.onResize();

    // lancement du chargement
    this.status = Status.loading;
    this.router.navigate(['/loading'], { skipLocationChange: true });
  }

  ngOnDestroy()
  {
    document.removeEventListener("mousemove", this._event_mouse_move.bind(this));
    if (this.activity_timeout != null) { clearTimeout(this.activity_timeout); this.activity_timeout = null; }
    if (this.session_timeout != null) { clearTimeout(this.session_timeout); this.session_timeout = null; }

    this.events.removeListener("EVENT_LOGIN_COMPLETE", this, this._event_login_complete);
    this.events.removeListener('EVENT_SCREEN_RESIZE', this, this._event_screen_resize);

    this.events.removeListener("EVENT_GOTO_STATUS", this, this._event_goto_status);
    this.events.removeListener("EVENT_TRANSITION_SAME", this, this._event_transition_same);
    this.events.removeListener("EVENT_TRANSITION_WAIT", this, this._event_transition_wait);
    this.events.removeListener("EVENT_TRANSITION_ENDED", this, this._event_transition_end);
    this.events.removeListener("EVENT_ELLIPSE_SHOW", this, this._event_ellipse_show);
    this.events.removeListener("EVENT_MENU_SHOW", this, this._event_menu_show);
    this.events.removeListener("EVENT_RES_POPUP_SHOW", this, this._event_res_popup_show);
    this.events.removeListener("EVENT_INF_POPUP_SHOW", this, this._event_inf_popup_show);
    this.events.removeListener("EVENT_CONFIRM_SHOW", this, this._event_confirm_show);
    this.events.removeListener("EVENT_BURGER_HIDE", this, this._event_burger_hide);
    this.events.removeListener("EVENT_QUICK_HIDE", this, this._event_quick_hide);
  }

  ngAfterViewInit(): void
  {
    // boutons cachés dès le début
    gsap.set(this.quickRef.nativeElement, { autoAlpha: 0 });
    gsap.set(this.burgerRef.nativeElement, { autoAlpha: 0 });
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  // bind
  public _resizeEvent(event: any): void
  {
    this.global.onResize();
  }

  ///////////////////////////////////////////////////////////////////////////////////////
  // events

  // après une connexion réussie
  private _event_login_complete(): void
  {
    this.global.log("login complete");

    // debug
    //this.global.gotoStatus(Status.ending);return;

    // on passe directement à la home en dev
    if (!this.global.dev)
      this.global.gotoStatus(Status.logo);
    else
      this.global.gotoStatus(Status.home);

    // raccourcis en mode testing
    if (this.global.testing)
    {
      //console.log("testing");
      var _this = this;
      document.addEventListener('keydown', function (evt: any)
      {
        //console.log(evt.keyCode);
        evt = evt || window.event;
        if (evt.keyCode == 73) // i
          _this.global.gotoStatus(Status.intro);
        else if (evt.keyCode == 79) // o
          _this.global.gotoStatus(Status.outro);
        else if (evt.keyCode == 69) // e
          _this.global.gotoStatus(Status.ending);
      });
    }

    // lancement des timers pour la session
    this._on_session_timeout(false);

    // debug
    //this.events.sendEvent("EVENT_CONFIRM_SHOW", [{show:'restart'}])
  }

  // détection de l'activité de l'utilisateur
  private _event_mouse_move(): void
  {
    // au delà de 30 secondes sans bouger la souris, il est considéré comme inactif
    this.activity_state = true;
    if (this.activity_timeout != null)
      clearTimeout(this.activity_timeout);

    let _this = this;
    _this.activity_timeout = setTimeout(function ()
    {
      _this.ngZone.run(() =>
      {
        _this.activity_state = false;
        //console.log("user inactive");
      })
    }, 30000);
  }

  // envoie une info de session si le joueur est toujours actif
  private _on_session_timeout(a_send: boolean = true): void
  {
    // vérification et envoi toutes les 30 secondes
    const _period = 30;
    if (a_send && this.activity_state)
    {
      this.storage.requestWebService("addUserTime", null, {
        userUID: this.data.uid,
        duration: _period
      })
    }

    let _this = this;
    this.session_timeout = setTimeout(function () { _this.ngZone.run(_this._on_session_timeout.bind(_this)) }, _period * 1000);
  }

  // changement d'état du jeu
  private _event_goto_status(): void
  {
    //this.global.log("_event_goto_status", this.global.status);

    // si on est sur le même status, on ne change rien
    if (this.global.status == this.status)
      return;

    // sinon on lance la transition vers le nouveau status
    this.transition = this.global.status;
  }

  private _event_transition_same(): void
  {
    // transition sur le même status
    this.transition = this.global.status;
  }

  // la transition a commencé, on est prêt à changer d'écran
  private _event_transition_wait(a_params: any): void
  {
    //this.global.log("_event_transition_wait", this.transition);

    // on ferme les popups éventuellements ouverts
    this.confirm = null;
    this.menu = false;
    this.status = this.transition;

    // on redéfinit l'état des boutons quick et burger
    this.events.sendEvent("EVENT_QUICK_HIDE", [{ hide: this.status != Status.ending }]);
    this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: (this.status == Status.logo || this.status == Status.login || this.status == Status.warning || this.status == Status.home || this.status == Status.intro || this.status == Status.outro || this.status == Status.playing) }]);

    // et on remet le bouton menu en mode normal
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "" }])

    // et on change d'écran
    let _route: string = "/" + Status[this.status];
    this.ngZone.run(() => { this.router.navigate([_route], { skipLocationChange: true }) });
  }

  // la transition est terminé
  private _event_transition_end(): void
  {
    //this.global.log("_event_transition_end", this.transition);
    // on n'a plus besoin du composant correspondant
    this.transition = Status.none;
  }

  _event_ellipse_show(a_params: { show?: boolean, content?: string, wait?: number }): void
  {
    // null = fin de l'ellipse
    var _ellipse: any | null = null;
    // affichage ellipse sans texte
    if (a_params.show == true)
      _ellipse = {};
    // affichage ellipse avec texte
    else if (a_params.content != null)
      _ellipse = { content: a_params.content };

    if (_ellipse != null && a_params.wait != null)
      _ellipse.wait = a_params.wait;
    this.ellipse = _ellipse;
  }

  // affichage ou fermeture du menu
  _event_menu_show(a_params: { show: boolean }): void
  {
    this.menu = a_params.show;
  }

  _event_res_popup_show(a_params: { show: boolean }): void
  {
    this.respopup = a_params.show;
  }

  _event_inf_popup_show(a_params: { show: string|null }): void
  {
    this.infpopup = a_params.show;
  }

  // permet de cacher le bouton burger, au moment de l'intro par exemple
  _event_burger_hide(a_params: { hide: boolean }): void
  {
    var _burger: boolean = !a_params.hide;
    if (this.burger == _burger) return;
    this.burger = _burger;
    if (!this.burger)
      gsap.set(this.burgerRef.nativeElement, { autoAlpha: 0 });
    else
      gsap.to(this.burgerRef.nativeElement,
        {
          duration: this.global.UI_FADE_DURATION,
          autoAlpha: 1
        });
  }

  _event_quick_hide(a_params: { hide: boolean }): void
  {
    var _quick: boolean = !a_params.hide;
    if (this.quick == _quick) return;
    this.quick = _quick;
    if (!this.quick)
      gsap.set(this.quickRef.nativeElement, { autoAlpha: 0 });
    else
      gsap.to(this.quickRef.nativeElement,
        {
          duration: this.global.UI_FADE_DURATION,
          autoAlpha: 1
        });
  }

  // affichage ou fermeture de la popup de confirmation
  _event_confirm_show(a_params: { show?: string }): void
  {
    this.confirm = (a_params != null && a_params.show != null && a_params.show.length > 0) ? a_params.show : null;
  }

  private _event_screen_resize()
  {
    this.classnames = this.global.root;
    this.style = "left:" + this.global.left + "px;top:" + this.global.top + "px;transform: scale(" + this.global.scale + ");";
    if (this.global.top > this.global.left)
    {
      this.black0 = "left:0;right:0;top:0;height:" + this.global.top + "px;";
      this.black1 = "left:0;right:0;bottom:0;height:" + this.global.top + "px;";
    }
    else
    {
      this.black0 = "top:0;bottom:0;left:0;width:" + this.global.left + "px;";
      this.black1 = "top:0;bottom:0;right:0;width:" + this.global.left + "px;";
    }
  }

  onBurger(a_mode: string)
  {
    // on ignore le click s'il est caché

    //this.global.log(a_mode);
    if (a_mode == "close")
    {
      this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "" }])
      if (this.respopup)
        this.events.sendEvent("EVENT_RES_POPUP_CLOSE");
      else if (this.infpopup!=null)
        this.events.sendEvent("EVENT_INF_POPUP_CLOSE");
      else
        this.events.sendEvent("EVENT_MENU_CLOSE");

      this.audio.playClose();
    }
    else if (a_mode == "back")
    {
      this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }])
      this.events.sendEvent("EVENT_MENU_BACK");
    }
    else
    {
      this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }])
      this.events.sendEvent("EVENT_MENU_SHOW", [{ show: true }]);
      this.audio.playOverlay1();
    }
  }

  onQuick(a_mode: string)
  {
    //this.global.log(a_mode);
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }])
    this.events.sendEvent("EVENT_RES_POPUP_SHOW", [{ show: true }]);
    this.audio.playOverlay1();
  }

}
