import { Injectable } from '@angular/core';

import { Global } from 'src/app/global';
import { EventsService } from './events.service';
import { Howl } from 'howler';
import { StorageService } from './storage.service';
import { DataService } from './data.service';

/**
 * Gestion audio
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class AudioService
{
  BG_VOLUME = 1;
  FX_VOLUME = 0.2;

  // fx ponctuel
  button: any = null;
  action: any = null;
  clickable: any = null;
  overlay1: any = null;
  overlay2: any = null;
  close: any = null;

  // audio lié au background
  bg: string | null = null;
  bgs: any = {};

  initialized: boolean = false;
  visibilityMute: boolean = false;

  constructor(private global: Global,
    private events: EventsService,
    private data: DataService,
    private storage: StorageService) { }

  // initialisation appelé lors du loading
  public init(): void
  {
    if (this.initialized)
      return;
    this.initialized = true;
    var _path = this.storage.getDataPath(this.global.PATH_AUDIO_FX);
    this.button = new Howl({ src: [_path + "Btn_Ressource.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });
    this.action = new Howl({ src: [_path + "Select_ChoixAction.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });
    this.clickable = new Howl({ src: [_path + "Select_ItemInteractif.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });
    this.overlay1 = new Howl({ src: [_path + "ApparitionEcran.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });
    this.overlay2 = new Howl({ src: [_path + "ApparitionEcran_2.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });
    this.close = new Howl({ src: [_path + "Btn_FermerMenu.mp3"], preload: true, autoplay: false, volume: this.FX_VOLUME });

    // mure du son sur perte de visibilité de la page
    var _this = this;
    document.addEventListener("visibilitychange", function ()
    {
      if (document.hidden)
      {
        //console.log("Browser tab is hidden")
        if (_this.data.user.audio == 1)
        {
          _this.visibilityMute = true;
          _this.muteBg();
        }
      }
      else
      {
        //console.log("Browser tab is visible")
        if (_this.visibilityMute)
        {
          _this.visibilityMute = false;
          _this.unmuteBg();
        }
      }
    });

    this.events.sendEvent("EVENT_AUDIO_READY");
  }

  public preloadBg(a_bg: string, a_loop: boolean = true): void
  {
    var _fullname = (a_bg.split('.').length > 1) ? a_bg : (a_bg + '.mp3');
    var _path: string = this.storage.getDataPath(this.global.PATH_AUDIO_BG + _fullname);
    //this.global.log("preload audio "+a_bg, _path);
    if (this.bgs[a_bg] == null)
      this.bgs[a_bg] = new Howl({ src: _path, loop: a_loop, preload: true, autoplay: false });
  }

  playBg(a_bg: string | null, a_immediate: boolean = false): void
  {
    //this.global.log("play bg "+a_bg+" "+this.bg+" playing");
    if (this.bg != a_bg)
    {
      // on arrête éventuellement un bg en cours
      if (this.bg != null)
        this.stopBg();

      this.bg = a_bg;

      if (this.bg != null && this.bgs[this.bg] != null)
      {
        var _audio = this.bgs[this.bg];
        //this.global.log("play audio"+this.bgs[this.bg]);
        _audio.off("fade");
        if (this.data.user.audio == 1)
        {
          if (a_immediate)
            _audio.volume(this.BG_VOLUME);
          else
            _audio.fade(0, this.BG_VOLUME, 1000);
        }
        else
          _audio.volume(0);
        _audio.play();
      }
    }
  }

  stopBg(a_duration: number = 1000)
  {
    if (this.bg != null && this.bgs != null && this.bgs[this.bg] != null)
    {
      //Global.log("stop bg "+this.bg);
      var _audio = this.bgs[this.bg];
      this.bg = null;

      if (this.data.user.audio == 1)
      {
        _audio.off("fade");
        _audio.fade(this.BG_VOLUME, 0.0, a_duration);
        _audio.once("fade", function ()
        {
          _audio.stop()
        });
      }
      else
        _audio.stop();
    }
  }

  muteBg()
  {
    if (this.bg != null)
    {
      //Global.log("stop bg "+this.bg);
      var _audio = this.bgs[this.bg];
      _audio.off("fade");
      _audio.fade(this.BG_VOLUME, 0.0, 1000);
    }
  }

  unmuteBg()
  {
    if (this.bg != null)
    {
      //Global.log("stop bg "+this.bg);
      var _audio = this.bgs[this.bg];
      _audio.off("fade");
      _audio.fade(0.0, this.BG_VOLUME, 1000);
    }
  }

  public playButton(): void
  {
    if (this.button != null && this.data.user.audio == 1)
    {
      this.button.play();
    }
  }

  public playAction(): void
  {
    if (this.action != null && this.data.user.audio == 1)
    {
      this.action.play();
    }
  }

  public playClickable(): void
  {
    if (this.clickable != null && this.data.user.audio == 1)
    {
      this.clickable.play();
    }
  }

  public playOverlay1(): void
  {
    if (this.overlay1 != null && this.data.user.audio == 1)
    {
      this.overlay1.play();
    }
  }

  public playOverlay2(): void
  {
    if (this.overlay2 != null && this.data.user.audio == 1)
    {
      this.overlay2.play();
    }
  }

  public playClose(): void
  {
    if (this.close != null && this.data.user.audio == 1)
    {
      this.close.play();
    }
  }

}
