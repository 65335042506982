import { Component, OnInit } from '@angular/core';
import { Global, Status } from 'src/app/global';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';

/**
 * Message à l'utilisateur s'il y a un problème avec le code dans l'URL
 * - périmé
 * - invalide
 * - non communiqué
 * 
 */

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit
{

  message: string | null = "";

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private storage: StorageService) { }

  ngOnInit(): void
  {
    if (this.storage.warning != null)
      this.message = this.storage.warning;
    /*else
      this.message = this.data.translate("ui.text.mainversion");*/
    this.events.sendEvent("EVENT_TRANSITION_END");
  }
}
