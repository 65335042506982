<div class="bg" (click)="onBackground()"></div>
<div #left class="left">
    <div class="title"></div>
    <div class="buttons">
        <app-button type="res-large" key="ui.menu.ressources" (handler)="onRes()"></app-button>
        <app-button type="yellow-large" *ngIf="restart" key="ui.menu.restart" (handler)="onRestart()"></app-button>
        <app-button type="yellow-large" key="ui.menu.mentions" (handler)="onMentions()"></app-button>
        <app-button type="yellow-large" key="ui.menu.confidentialite" (handler)="onPrivacy()"></app-button>
        <app-button type="yellow-large" key="ui.menu.credits" (handler)="onCredits()"></app-button>
        <div class="toggles">
            <div class="toggle-group">
                <div>
                    <div class="icon-audio"></div>
                    <div class="label">{{'ui.menu.sounds.'+(audioMode=='on'?'active':'inactive') | wording }}</div>
                </div>
                <app-button type="toggle" [mode]="audioMode" (handler)="onAudio()"></app-button>
            </div>
            <div class="toggle-group">
                <div>
                    <div class="icon-fullscreen"></div>
                    <div class="label">{{'ui.menu.fullscreen.'+(fullscreenMode=='on'?'active':'inactive') | wording }}</div>
                </div>
                <app-button type="toggle" [mode]="fullscreenMode" (handler)="onFullscreen()"></app-button>
            </div>
        </div>
    </div>
</div>
<div #right class="right">
    <app-resources *ngIf="res"></app-resources>
    <app-infos [type]="infos" *ngIf="infos"></app-infos>
</div>