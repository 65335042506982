import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';

import { Global, Status } from 'src/app/global';

import { AudioService } from 'src/app/services/audio.service';
import { DataService } from 'src/app/services/data.service';
import { FullscreenService } from 'src/app/services/fullscreen.service';

import gsap from 'gsap';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';


/**
 * Menu principal
 * 
 */

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit
{
  @ViewChild('left') left!: ElementRef;
  @ViewChild('right') right!: ElementRef;

  restart: boolean = false;

  audioMode: string = "on";
  fullscreenMode: string = "off";

  res: boolean = false;
  infos: string | null = null;

  tl: gsap.core.Timeline | null = null;
  interactable: Boolean = true;

  // gestion de l'affichage du bouton GEMAP
  displayResBtn: boolean = (this.storage.uid !== environment.demoUid);

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private audio: AudioService,
    private storage: StorageService,
    private fullscreen: FullscreenService,
    private ref: ElementRef,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.interactable = true;
    this.res = false;

    // fadein
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.UI_FADE_DURATION,
        autoAlpha: 1
      });

    // bouton restart seulement in game
    this.restart = this.global.status == Status.playing;

    // init des boutons toggle avec leur état courant
    this.audioMode = (this.data.user.audio == 1) ? "on" : "off";
    this.fullscreenMode = this.fullscreen.active ? "on" : "off";

    this.events.addListener("EVENT_MENU_CLOSE", this, this._event_menu_close);
    this.events.addListener("EVENT_MENU_BACK", this, this._event_menu_back);
  }

  ngOnDestroy(): void
  {
    // fin de l'animation en cours s'il y en a une
    if (this.tl != null)
    {
      this.tl.kill();
      this.tl = null;
    }

    this.events.removeListener("EVENT_MENU_CLOSE", this, this._event_menu_close);
    this.events.removeListener("EVENT_MENU_BACK", this, this._event_menu_back);
  }

  // appui sur bouton ressource
  onRes()
  {
    this.res = true;
    this._showRight();
  }

  // rédémarrage de la partie
  onRestart()
  {
    this.events.sendEvent("EVENT_CONFIRM_SHOW", [{ show: 'restart' }])
  }

  onMentions()
  {
    this.infos = "mentions";
    this._showRight();
  }

  onPrivacy()
  {
    this.infos = "confidentialite";
    this._showRight();
  }

  onCredits()
  {
    this.infos = "credits";
    this._showRight();
  }

  // toggle bouton audio
  onAudio()
  {
    if (this.audioMode == 'on')
    {
      this.audioMode = 'off';
      this.audio.muteBg();
      this.data.user.audio = 0;
      this.data.save();
    }
    else
    {
      this.audioMode = 'on';
      this.data.user.audio = 1;
      this.audio.unmuteBg();
      this.data.save();
    }
  }

  // toggle bouton fullscreen
  onFullscreen()
  {
    if (this.fullscreenMode == 'on')
      this.fullscreenMode = 'off';
    else
      this.fullscreenMode = 'on';
    this.fullscreen.toggle();
  }

  onBackground()
  {
    if (!this.interactable || this.tl != null) return;
    if (this.res || this.infos !=null)
    {
      this._event_menu_back();
      this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }])
    }
    else
    {
      this._event_menu_close();
      this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "" }])
    }
  }

  _showRight()
  {
    var _this = this;
    this.audio.playOverlay2();
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "back" }])

    this.tl = gsap.timeline().addLabel("start");
    this.tl?.to(this.left.nativeElement,
      {
        left: -this.global.GAME_WIDTH,
        autoAlpha: 0,
        duration: this.global.MENU_SLIDE_DURATION,
        ease: "power2.in"
      }, "start");
    this.tl?.to(this.right.nativeElement,
      {
        left: 0,
        autoAlpha: 1,
        duration: this.global.MENU_SLIDE_DURATION,
        ease: "power2.in"
      }, "start");
    this.tl?.call(function () { _this.tl = null });
  }

  _event_menu_back()
  {
    if (!this.interactable) return;

    this.audio.playOverlay2();
    var _this = this;

    this.tl = gsap.timeline().addLabel("start");
    this.tl?.to(this.left.nativeElement,
      {
        left: 0,
        autoAlpha: 1,
        duration: this.global.MENU_SLIDE_DURATION,
        ease: "power2.out"
      }, "start");
    this.tl?.to(this.right.nativeElement,
      {
        left: this.global.GAME_WIDTH,
        autoAlpha: 0,
        duration: this.global.MENU_SLIDE_DURATION,
        ease: "power2.out"
      }, "start");
    this.tl?.call(function ()
    {
      _this.ngZone.run(() =>
      {
        _this.res = false;
        _this.infos = null;
        _this.tl = null
      });
    });
  }

  _event_menu_close()
  {
    if (!this.interactable) return;

    // fadeout
    var _this = this;
    this.interactable = false;
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.UI_FADE_DURATION,
        autoAlpha: 0,
        onComplete: function ()
        {
          _this.events.sendEvent("EVENT_MENU_SHOW", [{ show: false }]);
        }
      });
  }
}
