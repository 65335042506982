<div #title class="title"></div>
<div class="inputs">
    <div #groupinput class="groupinput" *ngIf="!isOdysseusSession">
        <div class="label-code">{{'ui.connexion.title' | wording }}</div>
        <input #code type="text" [placeholder]="'ui.connexion.placeholder' | wording"/>
    </div>
    <div #groupinputOdysseus class="groupinput" *ngIf="isOdysseusSession">
        <div class="label-code">{{'ui.connexion.title.odysseus' | wording }}</div>
    </div>
    <div #groupcheck class="groupcheck">

        <input type="checkbox" #check/>
        <div class="label-check" [innerHTML]="'ui.connexion.text.data'|wording"></div>

    </div>
    <app-button #button type="yellow" key="ui.connexion.btn" (handler)="onConnect()"></app-button>
</div>
<div #links class="links">
    <div #mentions class="mentions" (click)="onMentions()">{{'ui.connexion.mentions' | wording }}</div>
    <div #confidentialite class="confidentialite" (click)="onConfidentialite()">{{'ui.connexion.confidentialite' | wording }}</div>
</div>