import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { Global, Rect } from 'src/app/global';
import { EngineService } from 'src/app/services/engine.service';
import { StorageService } from 'src/app/services/storage.service';
import { DataService } from 'src/app/services/data.service';
import { throwError } from 'rxjs';
import { AudioService } from 'src/app/services/audio.service';

import gsap from 'gsap';

/**
 * Ecran de jeu principal
 * 
 * Gère notamment la hitmap de la partie point & click
 * 
 */

export interface PointerEvent
{
  x: number;
  y: number;
};

@Component({
  selector: 'app-playing',
  templateUrl: './playing.component.html',
  styleUrls: ['./playing.component.scss']
})
export class PlayingComponent implements OnInit
{
  // canvas utilisé pour la hitmap
  canvasw: number = 192;
  canvash: number = 108;

  clickables: Array<string> = [];

  selected: number = -1;
  over: number = -1;

  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('intro') introRef!: ElementRef;
  @ViewChild('text') textRef!: ElementRef;

  hitmap: Uint8Array = new Uint8Array();

  images: any = {};
  counter: number = 0;

  // pour une suppression correcte des listeners, il vaut mieux les enregistrer
  safePointerMove: any = null;
  safePointerDown: any = null;
  safePointerLeave: any = null;

  // antispam
  nopointerdown: boolean = false;
  timeout: any = null;

  // intro la première fois qu'on arrive dans le jeu
  intro: boolean = false;

  // découpage du texte
  words: Array<string> = [];


  constructor(
    private global: Global,
    private events: EventsService,
    private audio: AudioService,
    private data: DataService,
    private storage: StorageService,
    private engine: EngineService,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.safePointerMove = this._onPointerMove.bind(this);
    this.safePointerDown = this._onPointerDown.bind(this);
    this.safePointerLeave = this._onPointerLeave.bind(this);

    this.events.addListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);
    //this.events.addListener("EVENT_ELLIPSE_WAIT", this, this._event_ellipse_wait);
    this.events.addListener("EVENT_POINTNCLICK_CLICKABLES", this, this._event_pointnclick_clickables);
    this.events.addListener("EVENT_POINTNCLICK_UNSELECT", this, this._event_pointnclick_unselect);
    this.events.addListener("EVENT_CLICKABLE_IMAGE_LOADED", this, this._event_clickable_image_loaded);
    this.events.addListener("EVENT_CLICKABLE_IMAGE_UNLOADED", this, this._event_clickable_image_unloaded);
    this.events.addListener("EVENT_CLICKABLE_DOWN", this, this._event_clickable_down);

    // mode intro    
    this.intro = this.global.intro;
  }

  ngOnDestroy(): void
  {
    // suppression du timeout en cours s'il y en a un 
    if (this.timeout != null) clearTimeout(this.timeout);

    this._leave_pointnclick();
    this.events.removeListener("EVENT_TRANSITION_ENDED", this, this._event_transition_ended);
    //this.events.removeListener("EVENT_ELLIPSE_WAIT", this, this._event_ellipse_wait);
    this.events.removeListener("EVENT_POINTNCLICK_CLICKABLES", this, this._event_pointnclick_clickables);
    this.events.removeListener("EVENT_POINTNCLICK_UNSELECT", this, this._event_pointnclick_unselect);
    this.events.removeListener("EVENT_CLICKABLE_IMAGE_LOADED", this, this._event_clickable_image_loaded);
    this.events.removeListener("EVENT_CLICKABLE_IMAGE_UNLOADED", this, this._event_clickable_image_unloaded);
  }

  ngAfterViewInit(): void
  {
    this.hitmap = new Uint8Array(this.canvasw * this.canvash);
    this._updateHitmap();

    // on cache ou montre les boutons suivant si on est en mode intro ou pas
    this.events.sendEvent("EVENT_QUICK_HIDE", [{ hide: this.intro }]);
    this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: this.intro }]);

    // démarrage du moteur en asynchrone pour éviter les soucis NG0100: Expression has changed after it was checked
    var _this = this;
    setTimeout(
      function ()
      {
        _this.engine.run();

        _this.events.sendEvent("EVENT_TRANSITION_END");
      }, 1);

    if (this.global.intro)
      this._intro();
  }

  _event_transition_ended()
  {
  }



  /*_event_ellipse_wait()
  {
    // une fois l'ellipse affichée on peut passer à la suite
    this.engine.next();

    // puis fin de l'ellipse
    this.events.sendEvent("EVENT_TRANSITION_END");
  }*/

  // mise à jour des objets/pnj à afficher
  _event_pointnclick_clickables(a_params: { clickables?: Array<string> })
  {
    var _clickables: Array<string> = (a_params != null && a_params.clickables != null) ? a_params.clickables : [];

    // on regarde les images manquantes
    this.counter = 0;
    for (var i = 0; i < _clickables.length; i++)
      if (this.images[_clickables[i]] == null) this.counter++;

    this.clickables = _clickables;
    //this.global.log("clickables", this.clickables);

    if (this.clickables.length > 0)
      this._enter_pointnclick();
    else
      this._leave_pointnclick();

    // s'il n'y a pas d'images à charger, on lance la mise à jour de la map, elle a possiblement changée
    if (this.counter == 0)
      this._updateHitmap();
  }

  _event_pointnclick_unselect()
  {
    // reset de la selection en cours
    this.selected = -1;
  }

  _event_clickable_image_loaded(a_params: { image: HTMLImageElement }): void
  {
    var _src: any = a_params.image.src;
    var _key: string = _src.split('/').pop().split(/*'_'*/'.')[0];
    this.images[_key] = a_params.image;
    //this.global.log("clickable image loaded", _key +" > "+this.counter);

    // une fois toutes les images traitées, on peut lancer la mise en place de la map
    this.counter--;
    if (this.counter == 0)
      this._updateHitmap();
  }

  _event_clickable_image_unloaded(a_params: { image: HTMLImageElement }): void
  {
    var _src: any = a_params.image.src;
    var _key: string = _src.split('/').pop().split(/*'_'*/'.')[0];
    if (this.images[_key] != null)
      delete this.images[_key];
    //this.global.log("clickable image unloaded", _key)
  }

  _event_clickable_down(a_params: { index: number }): void
  {
    // gestion d'un clic en dehors d'un élément clickable
    if (a_params != null && a_params.index == -1)
    {
      this.engine.pointnclick_action(-1);
    }
  }

  // gestion du survol et du click
  _enter_pointnclick(): void
  {
    this._leave_pointnclick();
    this.canvas.nativeElement.addEventListener("mousemove", this.safePointerMove);
    this.canvas.nativeElement.addEventListener("mouseleave", this.safePointerLeave);
    this.canvas.nativeElement.addEventListener("mouseout", this.safePointerLeave);
    this.canvas.nativeElement.addEventListener("mousedown", this.safePointerDown);
    //this.canvas.nativeElement.addEventListener("touchstart", this._onPointerDown.bind(this));
  }

  _leave_pointnclick(): void
  {
    this.canvas.nativeElement.removeEventListener("mousemove", this.safePointerMove);
    this.canvas.nativeElement.removeEventListener("mouseleave", this.safePointerLeave);
    this.canvas.nativeElement.removeEventListener("mouseout", this.safePointerLeave);
    this.canvas.nativeElement.removeEventListener("mousedown", this.safePointerDown);
    //this.canvas.nativeElement.removeEventListener("touchstart", this._onPointerDown.bind(this));
  }

  // remplissage du canvas avec les éléments clickables
  _updateHitmap(): void
  {
    //this.global.log("update hitmap");
    var _this = this;
    var _ctx = this.canvas.nativeElement.getContext('2d');

    // reset de la hitmap
    for (var i = 0; i < this.hitmap.length; i++)
      this.hitmap[i] = 255;

    // init du canvas
    _ctx.imageSmoothingEnabled = false;
    _ctx.clearRect(0, 0, _this.canvasw, _this.canvash);
    _ctx.globalAlpha = 1;

    var _scalerx: number = this.canvasw / this.global.GAME_WIDTH;
    var _scalery: number = this.canvash / this.global.GAME_HEIGHT;
    var _offsetx: number = this.global.BG_OFFSET;

    // remplissage du canvas et récupération des pixels concernés
    // l'ordre ne devrait pas avoir d'importance car on fait en sorte que les éléments ne se superposent pas
    for (var i = 0; i < this.clickables.length; i++)
    {
      var _index = i;
      var _clickable: string = this.clickables[_index];
      var _image: any = this.images[_clickable];
      if (_image == null) continue;
      var _rect: Rect = _this.data.scene.prop[_clickable /*+ "_over"*/];

      _ctx.drawImage(_image, _scalerx * (_rect.x - _offsetx), _scalery * _rect.y, _scalerx * _rect.w, _scalery * _rect.h);
      var _imagedata: ImageData = _ctx.getImageData(0, 0, _this.canvasw, _this.canvash);
      var _len4 = _imagedata.data.length >> 2;
      for (var j = 0; j < _len4; j++)
      {
        if (_imagedata.data[(j << 2) + 3] > 32 && this.hitmap[j] == 255)
          this.hitmap[j] = _index;
      }
    }
  }

  _getPointerIndex(e: any): number
  {
    var evt = undefined;
    if (e.changedTouches != null && e.changedTouches.length > 0)
    {
      evt = e.changedTouches.item(0);
    }
    if (e.pageX != null)
    {
      evt = e;
    }

    if (evt == undefined)
      return -1;

    var _x = evt.pageX;
    var _y = evt.pageY;

    var _cx = 0;
    var _cy = 0;
    var _cw = 0;
    var _ch = 0;
    try
    {
      var box = this.canvas.nativeElement.getBoundingClientRect();
      //this.global.log("box", box);
      _cx = box.x;
      _cy = box.y;
      _cw = box.width;
      _ch = box.height;
    } catch (e)
    {
      return -1;
    }

    if (_cw == 0 || _ch == 0)
      return -1;
    _x = (_x - _cx) / _cw;
    _y = (_y - _cy) / _ch;


    _x = Math.round(_x * this.canvasw);
    _y = Math.round(_y * this.canvash);
    var _index = this.hitmap[_x + this.canvasw * _y];
    //this.global.log("pointer", _x+"x"+_y+"="+_index);

    if (_index < this.clickables.length)
      return _index;
    else
      return -1;
  }

  _onPointerDown(e: any): void
  {
    var _this = this;

    // on ne peut pas cliquer si l'engine est déjà en cours de traitement
    if (this.engine.locked) return;

    // anti-spam, ça peut causer des soucis dans l'affichage
    if (this.nopointerdown) return;
    this.nopointerdown = true;
    this.timeout = setTimeout(function () { _this.ngZone.run(() => { _this.nopointerdown = false; }) }, 500);

    var index: number = this._getPointerIndex(e);
    this.selected = index;
    this.events.sendEvent("EVENT_CLICKABLE_DOWN", [{ index: index }]);
  }

  _onPointerMove(e: any): void
  {
    var index: number = this._getPointerIndex(e);
    if (index != this.over)
    {
      //this.global.log("pointer move", index);
      this.over = index;
    }
  }

  _onPointerLeave(e: any): void
  {
    //this.global.log("pointer leave");
    this.over = -1;
  }

  // animation d'intro
  _intro()
  {
    // apparition du texte d'intro à la fin du fondu au noir
    var _this = this;
    let _tl = gsap.timeline().addLabel("start");

    // écriture du texte d'intro text en fadein
    /*var _words = this.data.translate('ui.intro.text4', "");
    this.words = _words.split(' ');

    var _writer = { element: this.textRef.nativeElement, word: -1, progress: 0 };

    _tl.to(_writer, {
      duration: this.global.INTRO_WORD_DURATION * this.words.length,
      progress: this.words.length, ease: "linear",
      onUpdate: function ()
      {
        while (_writer.word < _writer.progress)
        {
          _writer.word++;
          var _content = _this.words.slice(0, _writer.word + 1).join(' ');
          _writer.element.innerHTML = _content;
        }
      }
    }, "start+="+this.global.INTRO_OFFICE_TEXT_START);*/
    gsap.set(this.introRef.nativeElement, {autoAlpha: 0});
    this.textRef.nativeElement.innerHTML = this.data.translate('ui.intro.text4', "");
    _tl.to(this.introRef.nativeElement, {autoAlpha: 1, duration: this.global.INTRO_TEXT_FADEIN_DURATION}, "start+="+this.global.INTRO_OFFICE_TEXT_START);
    

    // puis fadeout du texte
    _tl.to(this.introRef.nativeElement, {
      autoAlpha: 0,
      ease: "power2.in",
      duration: this.global.INTRO_FADE_DURATION,
      onComplete: function ()
      {
        _this.ngZone.run(() =>
        {
          // fin de l'intro
          _this.intro = false;
          _this.global.intro = false;

          // fin de la personnalisation du temps de transition lors de l'intro
          _this.global.overrideTransitionDuration = null;

          // affichage des boutons précédemment cachés
          _this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: false }]);
          _this.events.sendEvent("EVENT_QUICK_HIDE", [{ hide: false }]);

          _this.engine.next();
        });
      }
    }, "start+=" + (this.global.INTRO_OFFICE_DURATION-this.global.INTRO_FADE_DURATION));
  }
}
