<app-background></app-background>
<app-clickable *ngFor="let id of clickables; let index = index;" 
[index]="index" [id]="id" [over]="index==over" [selected]="index==selected"></app-clickable>

<canvas #canvas [width]="canvasw" [height]="canvash"></canvas>

<div #intro class="intro" *ngIf="intro">
    <div class="text">
        <div #text></div>
    </div>
</div>

<app-talker></app-talker>
<app-player></app-player>
