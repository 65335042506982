<div #title class="title"></div>

<div #message class="message"></div>

<div #buttons class="buttons">
    <app-button type="yellow" [key]="'ui.home.btn.'+(newgame?'play':'continue')" (handler)="onPlay()"></app-button>
    <app-button *ngIf="!newgame" type="yellow" key="ui.home.btn.restart" (handler)="onRestart()"></app-button>
</div>

<div #links class="links">
    <div class="mentions" (click)="onMentions()">{{'ui.connexion.mentions'|wording}}</div>
    <div class="confidentialite" (click)="onConfidentialite()">{{'ui.connexion.confidentialite'|wording}}</div>
</div>