import { Component, DebugElement, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Global, Status } from 'src/app/global';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';

import gsap from 'gsap';
import { AudioService } from 'src/app/services/audio.service';
import { StorageService } from 'src/app/services/storage.service';

/**
 * Ecran générique pour affichage intro/outro
 * 
 */

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit, OnDestroy
{
  // type de composant: intro/outro
  type: string = "";

  text: number = 1;
  index: number = 0;
  count: number = 0;

  tl: gsap.core.Timeline | null = null;

  @ViewChild('bgs') bgsRef!: ElementRef;
  @ViewChild('text') textRef!: ElementRef;
  @ViewChild('black') blackRef!: ElementRef;

  images: Array<any> = [];
  bgs: Array<any> = [];
  preloaded: number = 0;

  constructor(
    private global: Global,
    private storage: StorageService,
    private data: DataService,
    private events: EventsService,
    private audio: AudioService,
    private renderer: Renderer2,
    private ngZone: NgZone
  )
  {
    if (this.global.status == Status.intro)
    {
      this.type = "intro";
      this.count = 2;
    }
    else
    {
      this.type = "outro";
      this.count = 2;
    }
  }

  ngOnInit(): void
  {
    // la durée de transition est personnalisée
    if (this.type == "intro") this.global.overrideTransitionDuration = this.global.INTRO_FADE_DURATION;
    if (this.type == "outro") this.global.overrideTransitionDuration = this.global.OUTRO_FADEIN_DURATION;
  }

  ngOnDestroy(): void
  {
    // fin de l'animation si elle était encore en cours (sur polaroid par exemple)
    if (this.tl != null)
    {
      this.tl.kill();
      this.tl = null;
    }
  }

  ngAfterViewInit(): void
  {
    // on attends le chargement des images avant de lancer le fade in
    this.preload();
  }

  // chargement des images de fond
  preload(): void
  {
    var _this = this;

    const _outro_suffix = (this.data.session.decors == 1) ? "_formasup.jpg" : ".jpg";

    var _bgs: Array<string> = [];
    if (this.type == "intro") _bgs = ["bg-building.jpg", "bg-intro.jpg"];
    else if (this.type == "outro") _bgs = ["bg-outro" + _outro_suffix, "bg-building.jpg"];

    var _images: Array<string> = [];
    _bgs.map((a_image) => { _images.push(this.storage.getDataPath(this.global.PATH_BG + a_image)); });

    this.preloaded = _images.length;
    _images.map((a_image) =>
    {
      //_this.global.log("preload", a_image);
      var _image = new Image();
      _this.images.push(_image);
      _image.onload = () =>
      {
        _this.preloaded--;
        //_this.global.log("load complete for ", _image.src);
        if (_this.preloaded == 0)
          _this._ready();
      }
      _image.src = a_image;
    });
  }

  // on lance l'intro dès que les images sont chargées et instanciées
  private _ready(): void
  {
    this.ngZone.run(() =>
    {
      this.images.reverse().map((a_image) =>
      {
        const _div = this.renderer.createElement('img');
        this.renderer.appendChild(this.bgsRef.nativeElement, _div);
        this.renderer.addClass(_div, 'bg');
        this.renderer.setAttribute(_div, 'src', a_image.src);
        this.bgs.push(_div);
      })
      this.bgs = this.bgs.reverse();

      // lancement de la musique
      this.audio.playBg(this.type, true);

      if (this.type == "intro")
        this.stepIntro();
      else if (this.type == "outro")
        this.stepOutro();

      this.events.sendEvent("EVENT_TRANSITION_END");
    });
  }

  // affichage d'un slide intro
  stepIntro(): void
  {
    var _this = this;
    var _isBuilding: boolean = (this.index == 0);

    // reset
    this.initSlide(this.global.INTRO_FADE_DURATION);

    // dans le cas d'un slide building, on affiche deux textes, on programme ici l'affichage du premier
    if (_isBuilding)
    {
      this.tl?.call(function () { _this.textRef.nativeElement.innerHTML = _this.data.translate('ui.' + _this.type + '.text' + _this.text, ""); _this.text++; });
      this.tl?.to(this.textRef.nativeElement, { autoAlpha: 1, duration: this.global.INTRO_TEXT_FADEIN_DURATION }, "start+=" + this.global.INTRO_BUILDING_TEXT0_START);
      this.tl?.to(this.textRef.nativeElement, { autoAlpha: 0, duration: this.global.INTRO_TEXT_FADEOUT_DURATION }, "+=" + this.global.INTRO_BUILDING_TEXT0_WAIT);
    }

    // lancement du scrolling
    // l'ordre des background est inversé dans l'affichage, le premier est par dessus les autres
    var _scrollingTo: any = {
      top: -1080,
      ease: _isBuilding ? "power2.inOut" : "linear",
      duration: (_isBuilding ? this.global.INTRO_BUILDING_SCROLL_DURATION : this.global.INTRO_POLAROID_DURATION)
    }
    if (!_isBuilding) _scrollingTo.left = -280;
    this.tl?.to(this.bgs[this.index], _scrollingTo, (_isBuilding ? ("start+=" + this.global.INTRO_BUILDING_SCROLL_START) : "start"));

    // puis écriture d'un texte
    var _text1_start = _isBuilding ? (this.global.INTRO_BUILDING_SCROLL_START + this.global.INTRO_BUILDING_SCROLL_DURATION)
      : this.global.INTRO_POLAROID_TEXT_START;

    this.tl?.call(function () { _this.textRef.nativeElement.innerHTML = _this.data.translate('ui.' + _this.type + '.text' + _this.text, ""); _this.text++; },
      [], "start+=" + _text1_start);
    this.tl?.to(this.textRef.nativeElement, { autoAlpha: 1, duration: this.global.INTRO_TEXT_FADEIN_DURATION }, "start+=" + _text1_start);

    // changement de slide, toujours avec la même durée de fade
    var _slide_duration = (_isBuilding ? this.global.INTRO_BUILDING_DURATION : this.global.INTRO_POLAROID_DURATION);
    this.nextSlide(_slide_duration, this.global.INTRO_FADE_DURATION);
  }

  // affichage d'un slide outro
  stepOutro(): void
  {
    var _this = this;
    var _isBuilding: boolean = (this.index == 1);

    // reset
    this.initSlide(0, _isBuilding ? this.global.INTRO_OUTRO_CROSSFADE_DURATION : 0);
    if (_isBuilding)
      gsap.set(this.bgs[this.index], { top: -1080 });

    // dans le cas d'un slide building, on affiche deux textes, on programme ici l'affichage du premier
    if (_isBuilding)
    {
      this.tl?.call(function () { _this.textRef.nativeElement.innerHTML = _this.data.translate('ui.' + _this.type + '.text' + _this.text, ""); _this.text++; });
      this.tl?.to(this.textRef.nativeElement, { autoAlpha: 1, duration: this.global.OUTRO_TEXT_FADEIN_DURATION }, "start+=" + this.global.OUTRO_BUILDING_TEXT0_START);
      this.tl?.to(this.textRef.nativeElement, { autoAlpha: 0, duration: this.global.OUTRO_TEXT_FADEOUT_DURATION }, "+=" + this.global.OUTRO_BUILDING_TEXT0_WAIT);
    }

    // lancement du scrolling
    // l'ordre des background est inversé dans l'affichage, le premier est par dessus les autres
    var _scrollingTo: any = {
      top: _isBuilding ? 0 : -1080,
      ease: _isBuilding ? "power2.inOut" : "power1.out",
      duration: (_isBuilding ? this.global.OUTRO_BUILDING_SCROLL_DURATION : this.global.OUTRO_POLAROID_SCROLL_DURATION)
    }
    if (!_isBuilding) _scrollingTo.left = -280;
    this.tl?.to(this.bgs[this.index], _scrollingTo, (_isBuilding ? ("start+=" + this.global.OUTRO_BUILDING_SCROLL_START) : "start"));

    // dans le cas d'un slide building, on affiche le deuxième texte
    if (_isBuilding)
    {
      this.tl?.call(function () { _this.textRef.nativeElement.innerHTML = _this.data.translate('ui.' + _this.type + '.text' + _this.text, ""); _this.text++; },
        [], "start+=" + this.global.OUTRO_BUILDING_TEXT1_START);
      this.tl?.to(this.textRef.nativeElement, { autoAlpha: 1, duration: this.global.OUTRO_TEXT_FADEIN_DURATION }, "start+=" + this.global.OUTRO_BUILDING_TEXT1_START);
    }

    // mise en place du prochain slide
    var _slide_duration = _isBuilding ? this.global.OUTRO_BUILDING_DURATION : this.global.OUTRO_POLAROID_DURATION;
    this.nextSlide(_slide_duration, (_this.index >= (_this.count - 1)) ? this.global.OUTRO_FADEOUT_DURATION : 0);
  }

  // init commun à tous les slides avec ou sans fade
  // a_blackfade = fondu au noir éventuel
  // a_blackfade = crossfade entre les arrières plan éventuel
  initSlide(a_blackfade: number = 0, a_crossfade: number = 0): void
  {
    this.tl = gsap.timeline();

    // reset
    this.textRef.nativeElement.innerHTML = "";
    this.tl.set(this.textRef.nativeElement, { autoAlpha: 0 }).addLabel("start");

    // on cache l'image précédente s'il y en a une et on montre la nouvelle
    this.tl.set(this.bgs[this.index], { autoAlpha: 1 });
    if (this.index > 0)
    {
      if (a_crossfade)
        this.tl.to(this.bgs[this.index - 1], { autoAlpha: 0, duration: a_crossfade });
      else
        this.tl.set(this.bgs[this.index - 1], { autoAlpha: 0 });
    }

    // fadein au noir eventuel si on avait un autre slide avant
    if (this.index > 0 && a_blackfade > 0)
      this.tl.to(this.blackRef.nativeElement, { autoAlpha: 0, duration: a_blackfade }, "start");
  }

  // changement de slide avec ou sans fade au noir juste avant
  nextSlide(a_change: number, a_fade: number): void
  {
    var _this = this;
    var _starting = (a_change - a_fade);

    // puis on passe à la suite si on est sur le dernier slide
    if (_this.index >= (_this.count - 1))
    {
      this.tl?.call(
        function ()
        {
          _this.global.gotoStatus(_this.global.status == Status.intro ? Status.playing : Status.ending);
        }, [],
        "start+=" + _starting
      );
    }
    // ou alors on fait une transition sur le prochain slide
    else
    {
      // en fade ou cutout
      if (a_fade > 0)
        this.tl?.to(this.blackRef.nativeElement, { autoAlpha: 1, duration: a_fade }, "start+=" + _starting);

      this.tl?.call(function ()
      {
        _this.ngZone.run(() =>
        {
          _this.index++;
          if (_this.type == "intro")
            _this.stepIntro();
          else
            _this.stepOutro();
          _this.tl = null;
        });
      }, [], "start+=" + a_change);
    }
  }
}
