import { Injectable } from '@angular/core';
import { Global, Rect, Status } from 'src/app/global';
import { DataService } from './data.service';
import { EventsService } from './events.service';

/**
 * Moteur de jeu
 * 
 * On ne sauvegarde qu'à deux moments:
 * - lors d'une attente d'interaction joueur lors d'un dialogue
 * - sur le noeud principale d'une phase de point and click
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class EngineService
{
  ////////////////////////////////////////////////////////////////////////
  // public

  // jeu en cours
  running: boolean = false;

  // permet de bloquer temporairement pour éviter des problèmes d'actions concurrentes
  // typiquement le joueur lance une action dans le point&click et appuie sur le fond avant que l'action soit traitée
  locked: boolean = false;
    
  // noeud spécifiques
  start: string = "";
  current: string = "";

  // noeud sauvegardé
  // nécessairement un noeud dialogue pnj ou pointandclick (ou noeud avec talker éventuellement)
  // pour pouvoir reprendre la partie comme il faut avec la sauvegarde du background en parallèle
  checkpoint: string = "";

  // contexte
  pointnclick: boolean = false;

  vars: any = {};
  nodes: any = {};

  // etat courant du jeu
  state: any = {};

  background: string = "";
  music: string = "";

  ////////////////////////////////////////////////////////////////////////
  // privé

  // pour la suite du parcours
  _targets: Array<{ id: string, actions: Array<string> }> = [];
  _next_target: number = -1;
  _next_count: number = 1;
  _skip_next: boolean = false;

  _gameover: boolean = false;

  // message et choix en cours pour les stats
  _current_message_id: string = "";

  constructor(
    private global: Global,
    private events: EventsService,
    private data: DataService) { }

  // preload des assets avant le lancement
  preload(): void
  {
    var _background: string;

    // soit depuis la sauvegarde
    if (typeof this.data.user.progress["background"] != "undefined")
    {
      _background = this.data.user.progress["background"];
    }
    // soit récupération du premier background du jeu
    else
    {
      // chargement des noeuds
      this.nodes = this.data.graph;

      // récupération du noeud start
      this.start = "";
      for (var _id in this.nodes)
      {
        // récupération du node d'entrée
        if (this.nodes[_id].type == "Start")
          this.start = _id;
      }

      var _bgnode = this.nodes[this.start];
      while (_bgnode.type != "End" && _bgnode.type != "Background" && _bgnode.targets.length > 0)
      {
        _bgnode = this.nodes[_bgnode.targets[0].id];
      }
      _background = _bgnode.background.toLowerCase();
    }

    this.events.sendEvent("EVENT_BACKGROUND_PRELOAD", [{ background: _background }]);
  }

  // lancement du moteur
  run(): void
  {
    // trick  pour l'évaluation des conditions/actions
    (<any>window).engine = this;

    // lancement du jeu
    this.running = true;

    // chargement des noeuds
    this.nodes = this.data.graph;

    // récupération du noeud start
    this.start = "";
    for (var _id in this.nodes)
    {
      // récupération du node d'entrée
      if (this.nodes[_id].type == "Start")
        this.start = _id;
    }

    // récupération du premier background du jeu
    var _bgnode = this.nodes[this.start];
    while (_bgnode.type != "End" && _bgnode.type != "Background" && _bgnode.targets.length > 0)
    {
      _bgnode = this.nodes[_bgnode.targets[0].id];
    }

    //this.background = (_bgnode.type == "Background") ? _bgnode.background.toLowerCase() : "";
    //this.global.log("init bg = " + this.background);

    // reset
    this.pointnclick = false;

    // reset du node sauvegardé
    this.checkpoint = "";
    var _saved_id = this.data.user.progress["node"];
    for (var _id in this.nodes)
    {
      // récupération du node d'entrée
      if (_id == _saved_id)
        this.checkpoint = _id;
    }

    // debug
    //this.checkpoint = "n4::n26";
    //this.checkpoint = "n19::n36";
    //this.checkpoint = "n31::n11";
    //this.checkpoint = "n2::n5";
    //this.checkpoint = "n4";
    //this.data.user.progress["background"] = "clairierenuit";
    //this.data.user.progress["music"] = "BureauFinDeJournee.mp3";

    this.global.log("start node = ", this.checkpoint);

    // si jamais le node n'existe plus ou bien qu'il n'y avait pas de sauvegarde
    // alors on prends le node start
    if (this.checkpoint.length == 0)
      this.checkpoint = this.start;

    // démarrage, on se prépare pour aller sur le node sauvegardé
    this.nodes["_start_"] = { "type": "_start_", "targets": [{ "id": this.checkpoint, "actions": [] }] };
    this.current = "_start_";
    this._updateTargets();

    // reset de l'état courant du jeu
    this.state = {};

    // que l'on initialise avec les infos éventuellement sauvegardées
    // mais seulement si on est pas sur le node start, ça permet de gérer une sauvegarde concernant un ancien json
    //var _chapter_str = "" + this.chapter;
    for (var _key in this.data.user.progress)
    {
      if (_key.indexOf("$") == 0)
      {
        var _val = this.data.user.progress[_key];
        this.global.log("restore variable " + _key + " with " + _val);
        this.state[_key] = _val;
      }
    }

    // dans tout les cas on doit commencer par l'initialisation du background
    // c'est cet affichage qui déclenchera ensuite le premier next() du jeu

    // si on commence le chapitre on va se positionner directement sur le background
    this._next_target = -1;
    this._skip_next = false;
    if (this.checkpoint == this.start)
    {
      // sauvegarde initiale du noeud start pour indiquer le joueur a commencé la partie
      this.background = "";
      this.music = "";
      this._save();
      this.next();
    }

    // sinon récupération et initialisation du background
    else if (typeof this.data.user.progress["background"] != "undefined")
    {
      var _background = this.data.user.progress["background"];
      var _music = this.data.user.progress["music"];
      if (_background.length > 0)
      {
        // sur un changement de background, on fait disparaitre le talker également
        this.events.sendEvent("EVENT_TALKER");

        // remplacement immédiat
        this.background = _background;
        this.music = _music;
        this.events.sendEvent("EVENT_BACKGROUND", [{ background: this.background, music: _music }]);
      }
    }

    // reset des variables d'execution
    // on passera directement au noeud suivant
    this._gameover = false;
  }

  // appelé quand click sur un élément clickable
  pointnclick_action(a_index: number): void
  {
    this.global.log("pointnclick action " + a_index);
    // seulement si on est bien en mode point and click
    if (this.pointnclick)
    {
      // on revient sur le node principal au cas où on aurait déjà une action en cours
      this.current = this.checkpoint;
      this._updateTargets();
      // s'il n'y a pas d'action on reste sur la racine et on ferme l'affichage en cours
      if (a_index == -1)
        this.events.sendEvent("EVENT_PLAYER_HIDE");
      else
        this._next_target = a_index;
      this.next();
    }
  }

  // appelé lorsque le joueur répond à un choix
  player_answer(a_answer: number): void
  {
    // dans le cas d'un point & click, les targets étant déjà prises en compte, on sautera le node choisi par la suite
    // on ne peut pas le mettre avant comme pour les choices, car le choix peut être annulé
    if (this.pointnclick)
      this._skip_next = true;

    this.global.log("player answer " + a_answer);
    this._next_target = a_answer;

    // affichage de la réponse
    this.next();
  }

  // passage au noeud suivant
  goToTarget(a_index: number): void
  {
    // on passe au noeud enfant
    var _node = this.nodes[this.current];
    this._processActions(this._targets[a_index]);
    this.current = this._targets[a_index].id;
    //this.global.log("this._targets=", this._targets);

    // on saute les commentaires et les noeuds PointAndClickAction
    _node = this.nodes[this.current];
    while (_node.type.indexOf("Commentaire_") == 0 ||
      _node.type.indexOf("PointAndClickAction") == 0)
    {
      if (_node.targets[0].id !== null)
      {
        this._processActions(_node.targets[0]);
        this.current = _node.targets[0].id;
        _node = this.nodes[this.current];
      }
      else
        break;
    }

    // activation du mode point & click
    if (_node.type == "PointAndClick")
      this.pointnclick = true;

    // fin du mode point & click sur une action, on ne reprendra que si on revient sur le node point & click
    if (this.pointnclick && _node.type == "DialogElement" && _node.talker == "")
    {
      //this.global.log("fin du mode point & click");
      this.pointnclick = false;
      this.events.sendEvent("EVENT_POINTNCLICK_CLICKABLES");
    }

    // sauvegarde si on arrive sur un node pointandclick ou pnj (et eventuellement les dialogue avec talker)
    // attention à ne pas sauvegarder autre chose que le node racine en mode point & click
    if (_node.type == "PointAndClick" || (!this.pointnclick && _node.type == "DialogElement" && _node.talker.length > 0 && _node.talker != "empty"))
    {
      this.checkpoint = this.current;
      this._save();
    }

    // mise à jour des targets
    this._updateTargets();

  }

  // sauvegarde de la progression en cours
  _save(): void
  {
    this.data.user.progress["background"] = this.background;
    this.data.user.progress["music"] = this.music;
    this.data.user.progress["node"] = this.checkpoint;
    for (var _key in this.state)
      this.data.user.progress[_key] = this.state[_key];

    this.data.save();
  }

  // mise à jour des targets potentielles pour le noeud courant
  _updateTargets(): void
  {
    var _node = this.nodes[this.current];
    var _backgrounds: Array<string> = [];

    // récupération des targets pertinentes et valides pour ce noeud
    this._targets = [];
    for (var i = 0; i < _node.targets.length; i++)
    {
      var _target = _node.targets[i];
      var _target_node;

      // on concatène les actions de l'arborescence de noeud
      var _actions: Array<string> = [];

      var _checked = true;
      while (_checked)
      {
        _target_node = this.nodes[_target.id];

        // s'il y a une condition, on doit la tester pour savoir si le noeud est valide
        var _condition_checked = this._processConditions(_target);
        if (_condition_checked != null && !_condition_checked)
        {
          _checked = false;
          break;
        }


        // on saute les noeuds non pertinents
        // pour l'ellipse on se basera sur le talker
        if (_target_node.type.indexOf("Commentaire_") == 0
          || _target_node.type.indexOf("Dialogue_") == 0
          || _target_node.type.indexOf("Ellipse_") == 0)
        {
          _actions = _actions.concat(_target.actions);
          _target = _target_node.targets[0];
          if (_target.id == null)
            _checked = false;
        }
        else
          break;
      }
      _actions = _actions.concat(_target.actions);

      // TODO remplacer _target_node par _target
      // ajout de la target que si le chemin est validé
      if (_checked)
      {
        if (_target_node.type == "Background")
          _backgrounds.push(_target_node);
        this._targets.push({ id: _target.id, actions: _actions });
      }
    }

    //this.global.log("targets", this._targets);

    // preload éventuel d'un background s'il y en a un dans les targets
    if (_backgrounds.length == 1)
    {
      var _node: any = _backgrounds[0];
      //var _music: any = (_node.soundFile != null && _node.soundFile.length > 0) ? _node.soundFile : null;
      this.events.sendEvent("EVENT_BACKGROUND_PRELOAD", [{ background: _node.background.toLowerCase()/*, music: _music*/ }]);
    }
  }


  // étape suivante dans l'arbre narratif
  // initiée soit par l'utilisateur, soit par un évènement
  next(): void
  {
    var _this = this;

    //this.global.log("next");
    // permet d'éviter que le fadeout de l'ellipse ne se superpose à un fadein de background par exemple
    while (1)
    {
      var _node = this.nodes[this.current];

      // passage évenutel au noeud suivant (choix utilisateur ou noeud précédent traité)
      //this.global.log("next target =", this._next_target);
      if (this._next_target != -1)
      {
        //this.global.log("go to child " + this._next_target + " of " + this.current);
        this.goToTarget(this._next_target);
        _node = this.nodes[this.current];
        this._next_target = -1;

        // si le node est un dialogue sans talker, il s'agissait d'un choix utilisateur et on passe directement au noeud suivant
        if (_node.type == "DialogElement" && this._skip_next)
        {
          this._skip_next = false;
          this.global.log("skip node " + this.current, _node);
          this.goToTarget(0);
          _node = this.nodes[this.current];
        }

        this.global.log("go to node " + this.current, _node);

      }
      else
        this.global.log("read node " + this.current, _node);

      //this.global.log("targets count = ", _targets.length);


      // traduction systématique du texte, l'entrée text est ignorée
      var _rawtext: string = _node.text;
      var _text: string = _rawtext;
      var _key: string = _node.voiceFile;
      //tmp
      _text = this.data.translate(_key, /*this.global.dev ? _text : */null);
      //this.global.log("locale "+_key + " = "+_text);

      // tmp: fix talker à supprimer
      if (_text != null && _text.indexOf("@") != -1) _text = _text.substr(_text.lastIndexOf("@") + 1);
      _text = this._cleanMessage(_text);

      ///////////////////////////////////////////////////////////////////////////////////
      // noeuds spéciaux

      // fin de l'arbre narratif
      if (_node.type == "End")
      {
        setTimeout(function () { _this.data.user.progress = {}; _this.global.gotoStatus(Status.outro); });
        //this.events.sendEvent("EVENT_END", []);
        break;
      }

      // nouveau background
      else if (_node.type == "Background")
      {
        this.background = _node.background.toLowerCase();

        // on laisse le background s'afficher un moment la toute première fois
        this._next_target = 0;
        this.music = (_node.soundFile != null && _node.soundFile.length > 0) ? _node.soundFile : "";

        var _bg_params: any = { background: this.background, music: this.music };
        this.events.sendEvent("EVENT_BACKGROUND", [_bg_params]);

        // on arrête là, on attendra que le background soit bien affiché pour lancer la suite
        break;
      }

      // cas d'une ellipse
      else if (_node.talker == "Time")
      {
        var _params: any = { content: _text };
        var _tagsText: string = _node.text;
        while (_tagsText.length > 0 && _tagsText[0] == '[')
        {
          var _end = _tagsText.indexOf(']');
          var _tag = _tagsText.substring(1, _end);
          var _splitted = _tag.split('=');
          _tagsText = _tagsText.substring(_end + 1);
          _tag = _splitted[0].toLowerCase();
          if (_tag == "time")
          {
            _params.wait = parseFloat(_splitted[1]);
            //console.log("wait =", _params.wait);
          }
        }
        this._next_target = 0;
        this.events.sendEvent("EVENT_ELLIPSE_SHOW", [_params]);
        break;
      }

      ///////////////////////////////////////////////////////////////////////////////////
      // cas d'un point & click en cours
      var _checkpoint_node = this.nodes[this.checkpoint];
      if (_checkpoint_node.type == "PointAndClick")
      {
        // mise en place des éléments clickables du point & click
        if (_node.type == "PointAndClick")
        {
          // le passage en mode point & click fait disparaitre les personnages et dialogues encore affichés
          this.events.sendEvent("EVENT_TALKER");

          var _clickables: Array<string> = [];
          for (var i = 0; i < this._targets.length; i++)
          {
            var _id: string = this.nodes[this._targets[i].id].objectName;
            _clickables.push(_id.toLowerCase());
          }
          this.events.sendEvent("EVENT_POINTNCLICK_CLICKABLES", [{ clickables: _clickables }]);
          break;
        }
      }

      ///////////////////////////////////////////////////////////////////////////////////
      // il ne reste plus que du dialogue ou des noeuds qui ne servent à rien et que l'on saute

      if (_node.type != "DialogElement")
      {
        this.goToTarget(0);
        continue;
      }



      // le talker permet de connaitre le type de message
      var _talker = _node.talker.toLowerCase();

      var _empty: boolean = (_talker == "empty");
      var _pnj: boolean = _talker.length > 0 && _talker != "empty";


      // traitement d'une action point & click
      if (this.pointnclick)
      {
        var _actions: Array<string> = [];
        for (var i = 0; i < this._targets.length; i++)
          _actions.push(this._cleanMessage(this.data.translate(this.nodes[this._targets[i].id].voiceFile)));

        this.events.sendEvent("EVENT_PLAYER_ACTION", [{ description: _text, actions: _actions }]);
        break;
      }


      // sinon il s'agit d'un dialogue pnj ou joueur

      // extraction de tags éventuels dans le wording ou le texte raw le cas échéant
      var _didascalie: boolean = false;
      var _speed: number = 0;
      var _tagsFromText: boolean = (_text[0] == '[');
      var _tagsText: string = _tagsFromText ? _text : _rawtext;

      while (_tagsText.length > 0 && _tagsText[0] == '[')
      {
        var _end = _tagsText.indexOf(']');
        var _tag = _tagsText.substring(1, _end);
        var _splitted = _tag.split('=');
        _tag = _splitted[0].toLowerCase();
        //console.log("tag =", _tag);

        // pas nécessaire normalement
        if (_tag == "didascalie") _didascalie = true;
        else if (_tag == "speed" && _splitted.length > 1) _speed = parseFloat(_splitted[1]);
        else break;

        //console.log("tag " + _tag + " processed");
        _tagsText = _tagsText.substring(_end + 1);

        // on supprime le tag du wording si nécessaire
        if (_tagsFromText) _text = _tagsText;
      }

      //this.global.log("tags=", _tags);

      // sauvegarde du message en cours pour les stats
      this._current_message_id = _key;

      //this.global.log("_talker_params=", _talker_params);
      //this.global.log("_player_params=", _player_params);

      // est-ce qu'on arrive à un choix pour le joueur, auquel cas on attends sa réponse avant de passer à la suite
      var _need_choice: boolean = this._targets.length > 1;
      var _target_node: any = this.nodes[this._targets[0].id];
      if (!_need_choice && _pnj && _target_node.talker != null && _target_node.talker.length == 0)
        _need_choice = true;

      /*var _pnj_wait = false;
      if (_pnj && this._targets.length == 1 && this._targets[0].talker != null && this._targets[0].talker.length != 0)
        _pnj_wait = true;*/

      // dans tout les cas on attend qu'une animation ou une action utilisateur permette de passer à la suite
      this._next_target = _need_choice ? -1 : 0;

      // traitement du talker
      if (_empty)
        this.events.sendEvent("EVENT_TALKER");
      else if (_pnj)
      {
        var _params: any = { talker: _talker, content: _text };
        if (_speed > 0)
        {
          //this.global.log("speed=", _speed);
          _params.speed = _speed;
        }

        // si on n'attends pas d'entrée utilisateur, on passera direct à la suite après l'animation
        /*if (!_didascalie && !_need_choice)
          _talker_params.next = true;*/

        this.events.sendEvent("EVENT_TALKER", [_params]);
      }

      // traitement du player
      // cas d'une didascalie 
      if (_didascalie)
      {
        // le player ne sera mis à jour qu'une fois l'animation talker terminée
        this.events.sendEvent("EVENT_PLAYER_DIDASCALIE", [{ message: _text }]);
      }
      // cas de l'attente d'une réponse (choix unique ou multiple du prochain node)
      else if (_need_choice)
      {
        var _choice: Array<string> = [];
        for (var i = 0; i < this._targets.length; i++)
          _choice.push(this._cleanMessage(this.data.translate(this.nodes[this._targets[i].id].voiceFile)));

        // les targets étant déjà prises en compte, on sautera le node choisi par la suite
        this._skip_next = true;

        this.events.sendEvent("EVENT_PLAYER_CHOICE", [{ choice: _choice }]);
      }
      // cas d'un dialogue joueur sans lien avec un dialogue pnj
      else if (!_pnj)
      {
        this.events.sendEvent("EVENT_PLAYER_CHOICE", [{ choice: [_text] }]);
      }
      // cas d'un dialogue pnj
      else
      {
        this.events.sendEvent("EVENT_PLAYER_WAIT");
      }


      // dans tous les cas, on attends la fin de l'affichage ou une réponse utilisateur avant de passer à la suite
      break;
    }
  }


  stop(): void
  {
    // fin du jeu
    this.running = false;
  }

  ////////////////////////////////////////////////////////////////////////
  // privé

  _cleanMessage(a_message: string): string
  {
    // corrections <br> + non breaking space + trim
    /*a_message = a_message.replace(/<br>/g, "<br/>").
      replace(/\s\?/g, "&nbsp;?").
      trim();
   
    // suppression des saut de ligne et début et en fin
    while (a_message.indexOf("<br/>") == 0)
      a_message = a_message.substr(5);
    while (a_message.length >= 5 && a_message.indexOf("<br/>") == (a_message.length - 5))
      a_message = a_message.substr(0, a_message.length - 5);
    */
    return a_message;
  }

  _processActions(a_node: any): void
  {
    for (var _index in a_node.actions)
    {
      var _key = null;
      var _value = null;
      var _action = a_node.actions[_index];
      //this.global.log("process action " + _action + " for node " + a_node);

      var _actionToEval = _action.replace(/\$/g, "window.engine.state.$").replace(/;/g, "");
      try { eval(_actionToEval); }
      catch (e: any)
      {
        this.global.error("Erreur sur l'évaluation de l'action " + _actionToEval + " " + e.toString());
      }
    }
  }


  _processConditions(a_node: any): boolean | null
  {
    var _conditions = a_node.conditions;
    if (_conditions == null) return true;

    /*if (_conditions.length == 0)
    {
      // cas spécial où la target est un Dialogue_End
      // et que la condition se trouve dessus
      if (this.nodes[a_node.id].type == "Dialogue_End"
        && this.nodes[a_node.id].targets.length == 1
        && this.nodes[a_node.id].targets[0].conditions.length > 0)
      {
        _conditions = this.nodes[a_node.id].targets[0].conditions
        this.global.log("cas spécial, conditions = " + _conditions);
      }
    }*/

    var _condition = _conditions.join('');
    if (_conditions.length == 0) return true;

    //this.global.log("process condition " + _condition);

    var _conditionToEval = _condition.replace(/\$/g, "window.engine.state.$").replace(/;/g, "");
    var _condition_result = false;
    try { _condition_result = eval(_conditionToEval); }
    catch (e: any)
    {
      this.global.error("Erreur sur l'évaluation de la condition " + _conditionToEval + " " + e.toString());
      _condition_result = false;
    }
    //console.log(_condition_result);
    return _condition_result;
  }

}
