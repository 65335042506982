import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Global, Status } from 'src/app/global';
import { EventsService } from 'src/app/services/events.service';
import gsap from 'gsap';

@Component({
  selector: 'app-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.scss']
})
export class TransitionComponent implements OnInit
{
  @Input('status') status: Status | null = Status.none;
  
  constructor(
    private global: Global,
    private events: EventsService,
    private ref: ElementRef) { }

  ngOnInit(): void
  {
    this.global.transition = true;
    this.events.addListener("EVENT_TRANSITION_END", this, this._event_transition_end);
    this._start();
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_TRANSITION_END", this, this._event_transition_end);
    this.global.transition = false;
  }

  _start()
  {
    // ce n'est pas une bonne idée de tout couper, il vaut mieux que chaque composant gère ses animations
    // par exemple les icones sur le footer ont besoin d'etre animés lors d'un changement d'écran

    // du coup on stoppe juste l'animation de la transition en cours
    gsap.killTweensOf(this.ref.nativeElement);

    var _this = this;
    this.events.sendEvent("EVENT_TRANSITION_STARTING", [{ status: _this.status }]);

    // à priori, le changement de couleur de fond serait plus performant
    // fadein
    //_transition.addClass("noevents");
    gsap.to(this.ref.nativeElement,
      {
        autoAlpha: 1,
        ease: "power2.out",
        duration: (this.global.overrideTransitionDuration!=null)?this.global.overrideTransitionDuration:this.global.TRANSITION_DURATION,
        onComplete: function () 
        {
          _this.events.sendEvent("EVENT_TRANSITION_CLEAN");
          _this.events.sendEvent("EVENT_TRANSITION_WAIT", [{ status: _this.status }]);
        }
      });
  }


  _event_transition_end()
  {
    // fadeout
    var _this = this;
  	
    //_transition.removeClass("noevents");
    gsap.to(this.ref.nativeElement, 
    {
      duration: (this.global.overrideTransitionDuration!=null)?this.global.overrideTransitionDuration:this.global.TRANSITION_DURATION,
      autoAlpha: 0,
      ease: "power2.out",
      onComplete: function() 
      {
        _this.events.sendEvent("EVENT_TRANSITION_ENDED", [{ status: _this.status}]);
      }
    });

    _this.events.sendEvent("EVENT_TRANSITION_ENDING");
  }


}
