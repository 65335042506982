import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

import { Global, Status } from 'src/app/global';
import { EventsService } from 'src/app/services/events.service';

import gsap from 'gsap';

/**
 * Ecran générique pour affichage mentions légales/politique de confidentialité/crédits
 * 
 */

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit
{
  @Input('popup') popup: boolean = false;

  // type de composant: mentions/credits
  @Input('type') type: string = "";

  @HostBinding('class') classname = '';

  constructor(
    private global: Global,
    private events: EventsService,
    private ref: ElementRef
  ) { }

  ngOnInit(): void
  {
    //this.global.log("show info "+ this.type);
    this.classname = this.type;
    this.events.addListener("EVENT_INF_POPUP_CLOSE", this, this._event_inf_popup_close);
  }

  ngOnDestroy(): void
  {
    this.events.removeListener("EVENT_INF_POPUP_CLOSE", this, this._event_inf_popup_close);
  }

  ngAfterViewInit(): void
  {
    if (this.popup)
      gsap.to(this.ref.nativeElement, { duration: this.global.UI_FADE_DURATION, autoAlpha: 1 });
    else
      gsap.set(this.ref.nativeElement, { autoAlpha: 1 });
  }

  // click sur background lorsqu'on est en mode popup
  onBackground()
  {
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "" }])
    this._event_inf_popup_close();
  }

  _event_inf_popup_close()
  {
    // si on est sur l'écran loading, on recache le bouton burger
    if (this.global.status == Status.login)
      this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: true }]);

    // fadeout
    var _this = this;
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.UI_FADE_DURATION,
        autoAlpha: 0,
        onComplete: function ()
        {
          _this.events.sendEvent("EVENT_INF_POPUP_SHOW", [{ show: null }]);
        }
      });
  }

}
