import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { EngineService } from 'src/app/services/engine.service';
import { EventsService } from 'src/app/services/events.service';

import { Global } from 'src/app/global';

import gsap from 'gsap';
import { AudioService } from 'src/app/services/audio.service';

/**
 * Composant d'affichage d'ellipse
 * 
 * à la base pour les ellipses temporelles avec du texte à afficher
 * mais sert également pour les transitions entre deux décors
 */

@Component({
  selector: 'app-ellipse',
  templateUrl: './ellipse.component.html',
  styleUrls: ['./ellipse.component.scss']
})
export class EllipseComponent implements OnInit
{
  @Input('content') content: string = "";
  @Input('wait') wait: number | null = null;

  @ViewChild('txt') txt!: ElementRef;

  constructor(
    private global: Global,
    private audio: AudioService,
    private events: EventsService,
    private engine: EngineService,
    private ref: ElementRef,
    private ngZone: NgZone)
  {
  }

  ngOnInit(): void
  {
    this.global.ellipse = true;
  }

  ngAfterViewInit(): void
  {
    // suppression de toutes les anciennes props
    this.events.sendEvent("EVENT_POINTNCLICK_CLICKABLES");

    // comme pour un changement de décor, on attend d'être sûr que tous les persos aient disparu
    this.events.sendEvent("EVENT_TALKER");

    if (this.global.ready.talker)
      this.fadein();
    else
      this.events.addListener("EVENT_TALKER_READY", this, this._event_talker_ready);
  }

  ngOnDestroy(): void
  {
    this.global.ellipse = false;
    gsap.killTweensOf(this.ref.nativeElement);
    this.events.removeListener("EVENT_TALKER_READY", this, this._event_talker_ready);
    this.events.removeListener("EVENT_ELLIPSE_END", this, this._event_ellipse_end);
  }

  // si on était en attente pour afficher l'ellipse et que le talker est maintenant vide
  _event_talker_ready()
  {
    // alors on peut passer à l'affichage de l'ellipse
    if (this.global.ready.talker)
      this.fadein();
  }

  // fadein l'ellipse
  fadein()
  {
    this.events.removeListener("EVENT_TALKER_READY", this, this._event_talker_ready);

    var _this = this;

    // une ellipse
    var _isTimeEllipse: boolean = this.content != null && this.content.length > 0;

    // quoiqu'il arrive on stoppe la musique au lancement de l'ellipse
    if (_isTimeEllipse)
      this.audio.stopBg();

    // puis fadein
    gsap.to(this.ref.nativeElement,
      {
        duration: this.global.ELLIPSE_TIME_FADE,
        autoAlpha: 1,
        onComplete: function () 
        {
          if (_isTimeEllipse)
          {
            // poursuite de l'ellipse
            _this.reveal();
          }
          else
          {
            // on attends la confirmation du créateur de l'ellipse avant de passer à la suite
            _this.events.addListener("EVENT_ELLIPSE_END", _this, _this._event_ellipse_end);
            _this.events.sendEvent("EVENT_ELLIPSE_WAIT");
          }
        }
      });
  }

  // on passe à la suite dès qu'on a reçu le message indiquant qu'on peut y aller
  _event_ellipse_end(a_params: { content?: string }): void
  {
    this.events.removeListener("EVENT_ELLIPSE_END", this, this._event_ellipse_end);
    this.fadeout();
  }

  // affichage du texte dans le cas d'une ellipse normale
  reveal() 
  {
    var _this = this;

    //console.log("ellipse wait ="+this.wait);

    // dans le cas d'une ellipse venant de l'engine, c'est à ce moment qu'on passe à la suite du scénario
    this.engine.next();

    // fondu d'affichage
    gsap.to(this.txt.nativeElement,
      {
        duration: this.global.ELLIPSE_TIME_REVEAL,
        autoAlpha: 1,
        onComplete: function () 
        {
          gsap.delayedCall((_this.wait != null) ? _this.wait : _this.global.ELLIPSE_TIME_WAIT_DEFAULT, _this.fadeout.bind(_this));
        }
      });
  }

  // fadeout de l'ellipse
  fadeout()
  {
    this.ngZone.run(() =>
    {
      var _this = this;

      // fadeout texte
      var _tl = gsap.timeline();
      _tl.to(this.txt.nativeElement,
        {
          duration: this.global.ELLIPSE_TIME_REVEAL,
          autoAlpha: 0,
          onComplete: function () 
          {
            // on indique le lancement du fadeout, ce qui permet de déclencher une animation par exemple
            _this.events.sendEvent("EVENT_ELLIPSE_ENDING");
          }
        });

      // puis fadeout noir
      _tl.to(this.ref.nativeElement,
        {
          duration: this.global.ELLIPSE_TIME_FADE,
          autoAlpha: 0,
          onComplete: function () 
          {
            _this.events.sendEvent("EVENT_ELLIPSE_SHOW", [{ show: false }]);
          }
        });
    });
  }

}
