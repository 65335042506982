import { Component, ElementRef, HostBinding, HostListener, NgZone, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EngineService } from 'src/app/services/engine.service';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';

import { Global } from 'src/app/global';

import gsap from 'gsap';
import { AudioService } from 'src/app/services/audio.service';

/**
 * Dialogue/Didascalie utilisateur
 * 
 * N'est affiché que s'il n'y a pas d'animation talker en cours
 * 
 */

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit
{
  shown: boolean = false;

  didascalie: boolean = false;
  content: Array<string> = [];
  actions: Array<string> | null = null;

  next_didascalie: boolean | null = null;
  next_content: Array<string> | null = null;
  next_actions: Array<string> | null = null;

  clicking: boolean = false;
  timeout: any = null;
  duration: number = 0;

  @HostBinding('class') classname = '';

  interactable: boolean = false;
  fullInteractable: boolean = false;

  constructor(
    private global: Global,
    private data: DataService,
    private events: EventsService,
    private storage: StorageService,
    private engine: EngineService,
    private audio: AudioService,
    private ref: ElementRef,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    this.events.addListener("EVENT_PLAYER_HIDE", this, this._event_player_hide);
    this.events.addListener("EVENT_PLAYER_CHOICE", this, this._event_player_choice);
    this.events.addListener("EVENT_PLAYER_WAIT", this, this._event_player_wait);
    this.events.addListener("EVENT_PLAYER_DIDASCALIE", this, this._event_player_didascalie);
    this.events.addListener("EVENT_PLAYER_ACTION", this, this._event_player_action);
    this.events.addListener("EVENT_TALKER_READY", this, this._event_talker_ready);
  }

  ngOnDestroy(): void
  {
    // suppression du timeout en cours s'il y en a un 
    if (this.timeout != null) clearTimeout(this.timeout);
    this.events.removeListener("EVENT_PLAYER_HIDE", this, this._event_player_hide);
    this.events.removeListener("EVENT_PLAYER_CHOICE", this, this._event_player_choice);
    this.events.removeListener("EVENT_PLAYER_WAIT", this, this._event_player_wait);
    this.events.removeListener("EVENT_PLAYER_DIDASCALIE", this, this._event_player_didascalie);
    this.events.removeListener("EVENT_PLAYER_ACTION", this, this._event_player_action);
    this.events.removeListener("EVENT_TALKER_READY", this, this._event_talker_ready);
  }

  updateFullInteractable()
  {
    // clic sur le fond que pour didascalie et choix unique  avec bouton suivant
    this.fullInteractable = this.interactable && this.actions == null && (this.didascalie || this.content.length <= 1);
    this.classname = this.fullInteractable ? "full-interactable" : "";
  }

  _event_talker_ready(): void
  {
    this.global.ready.player = this.global.ready.talker;

    // on lance l'affichage maintenant que le background a bien été affiché avant
    if (this.global.ready.player && this.next_content != null)
      this._updateDisplay();
  }

  _event_player_choice(a_params: { choice: Array<string> }): void
  {
    //this.global.log("EVENT_PLAYER ", a_params);
    this.next_actions = null;
    this.next_didascalie = null;
    this.next_content = a_params.choice;

    if (this.global.ready.player)
      this._updateDisplay();
  }

  // demande d'affichage d'une didascalie
  _event_player_didascalie(a_params: { message: string })
  {
    this.next_actions = null;
    this.next_didascalie = true;
    this.next_content = [a_params.message];

    if (this.global.ready.player)
      this._updateDisplay();
  }

  // attente du joueur pour passer à la suite (bouton suivant)
  _event_player_wait()
  {
    this.next_actions = null;
    this.next_didascalie = null;
    this.next_content = [];

    if (this.global.ready.player)
      this._updateDisplay();
  }

  // description/actions d'un élément clickable lors de la phase de point & click
  _event_player_action(a_params: { description: string, actions: Array<string> }): void
  {
    this.next_actions = a_params.actions;
    this.next_didascalie = null;
    this.next_content = [a_params.description];

    if (this.global.ready.player)
      this._updateDisplay();
  }

  _event_player_hide()
  {
    var _this = this;

    // on ferme l'affichage en cours
    this.next_actions = null;
    this.next_didascalie = null;
    this.next_content = null;
    var _tl = gsap.timeline();

    // on ne peut pas faire quoique ce soit sur l'engine tant que l'animation n'est pas terminée
    this.engine.locked = true;
    _tl.to(this.ref.nativeElement, { duration: this.duration, ease: "power2.in", bottom: -150, autoAlpha: 0 });
    _tl.call(function () { _this.engine.locked = false; })
  }

  _updateDisplay(): void
  {
    var _this = this;

    // le type d'affichage déterminera la durée de l'animation
    this.duration = (_this.next_actions != null) ? this.global.ACTION_DURATION : this.global.PLAYER_DURATION;

    // on ne peut pas faire quoique ce soit sur l'engine tant que l'animation n'est pas terminée
    this.engine.locked = true;

    var _tl = gsap.timeline();
    if (this.shown) _tl.to(this.ref.nativeElement, { duration: this.duration, ease: "power2.in", autoAlpha: 0 });
    _tl.call(function () 
    {
      _this.ngZone.run(() =>
      {
        _this.actions = (_this.next_actions != null) ? _this.next_actions : null;
        _this.didascalie = (_this.next_didascalie != null) ? _this.next_didascalie : false;
        _this.content = (_this.next_content != null) ? _this.next_content : [];

        _this.next_actions = null;
        _this.next_didascalie = null;
        _this.next_content = null;
      });
    });

    // affichage
    _tl.to(this.ref.nativeElement, { duration: this.duration, ease: "power2.out", bottom: 0, autoAlpha: 1 });
    _tl.call(function ()
    {
      _this.ngZone.run(() =>
      {
        //console.log("interactable on");
        _this.engine.locked = false;
        _this.interactable = true;
        _this.updateFullInteractable();
      });
    })
    this.shown = true;
  }

  onChoiceClick(e: Event, a_index: number = 0): void
  {
    if (!this.interactable) return;
    //console.log("interactable off");
    this.interactable = false;
    this.updateFullInteractable();
    var _this = this;
    var _tl = gsap.timeline();
    _tl.to(e.target, { duration: this.global.BUTTON_SCALE_DURATION, scale: 1, ease: "power2.out" });
    _tl.call(function ()
    {
      _this.ngZone.run(() =>
      {
        // on bypass le check interactable qui a déjà été fait avant
        _this.onNext(a_index, true);
      });
    });
  }

  // bouton suivant
  onNext(a_index: number = 0, a_force: boolean = false): void
  {
    if (!this.interactable && !a_force) return;

    // on ne peut pas cliquer si l'engine est déjà en cours de traitement
    if (this.engine.locked) return;
    
    // on ne peut pas faire quoique ce soit sur l'engine tant que l'animation n'est pas terminée
    this.engine.locked = true;

    //console.log("interactable off");
    this.interactable = false;
    this.updateFullInteractable();
    //this.timeout = setTimeout(function () { _this.ngZone.run(() => { _this.clicking = false; }) }, 500);

    if (this.actions == null && this.content != null && this.content.length > 1)
      this.audio.playAction();

    // dans le cas d'une action point&click on déselectionne l'item en cours avant de commencer l'animation
    if (this.actions != null)
      this.events.sendEvent("EVENT_POINTNCLICK_UNSELECT");

    var _this = this;

    var _tl = gsap.timeline();

    _tl.to(this.ref.nativeElement, { duration: this.duration, ease: "power2.in", bottom: -150, autoAlpha: 0 });
    _tl.call(function () 
    {
      _this.ngZone.run(() =>
      {
        _this.engine.locked = false;
        _this.shown = false;
        _this.engine.player_answer(a_index);
      });
    });
  }

  onMouseEnter(e: Event): void
  {
    if (this.interactable)
      gsap.to(e.target, { duration: this.global.BUTTON_SCALE_DURATION, scale: this.global.PLAYER_CHOICE_SCALE_UP, ease: "power2.out" });
  }

  onMouseLeave(e: Event)
  {
    // seulement si on a pas déjà clické
    if (this.interactable)
      gsap.to(e.target, { duration: this.global.BUTTON_SCALE_DURATION, scale: 1.0, ease: "power2.in" });
  }

  @HostListener('click', [])
  onClick()
  {
    // clic sur le fond que pour didascalie et choix unique  avec bouton suivant
    if (this.fullInteractable)
      this.onNext();
  }
}
