import { Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { EventsService } from 'src/app/services/events.service';
import { EngineService } from 'src/app/services/engine.service';
import { DataService } from 'src/app/services/data.service';

import { Global, Rect } from 'src/app/global';

import gsap from 'gsap';
import { StorageService } from 'src/app/services/storage.service';
import { AudioService } from 'src/app/services/audio.service';

/**
 * 
 * Element clickable dans la phase de point & click
 * 
 */

@Component({
  selector: 'app-clickable',
  templateUrl: './clickable.component.html',
  styleUrls: ['./clickable.component.scss']
})
export class ClickableComponent implements OnInit, OnChanges
{
  showing: boolean = false;

  @Input('index') index: number = 0;
  @Input('id') id: string = "";
  @Input('img') img: string = "";
  @Input('selected') selected: boolean = false;
  @Input('over') over: boolean = false;

  image: HTMLImageElement | null = null;


  constructor(
    private global: Global,
    private audio: AudioService,
    private data: DataService,
    private storage: StorageService,
    private events: EventsService,
    private engine: EngineService,
    private ref: ElementRef,
    private ngZone: NgZone) { }

  ngOnInit(): void
  {
    //this.global.log(this.id);
    this.img = this.id.toLowerCase() /*+ "_over"*/;

    var _rect: Rect = this.data.scene.prop[this.img];
    if (_rect == null) return;

    var _path = this.storage.getDataPath(this.global.PATH_PROP + this.img);

    var _this = this;
    this.image = new Image();
    this.image.onload = function ()
    {
      _this.events.sendEvent("EVENT_CLICKABLE_IMAGE_LOADED", [{ image: this }])
    }
    this.image.src = this.storage.getDataPath(this.global.PATH_PROP + this.img + ".png");

    var _url_img = 'url("' + _path + '.png")';
    gsap.set(this.ref.nativeElement, {
      left: _rect.x-this.global.BG_OFFSET, top: _rect.y, width: _rect.w, height: _rect.h,
      backgroundImage: _url_img,
      autoAlpha: 0
    });

    this.events.addListener("EVENT_CLICKABLE_DOWN", this, this._event_clickable_down);
    //this.global.log("create clickable", this.index+" "+this.id);
  }

  ngOnDestroy(): void
  {
    this.events.sendEvent("EVENT_CLICKABLE_IMAGE_UNLOADED", [{ image: this.image }])
    this.events.removeListener("EVENT_CLICKABLE_DOWN", this, this._event_clickable_down);
    //this.global.log("destroy clickable", this.index+" "+this.id);
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    for (var key in changes)
    {
      if (key == 'over' || key == 'selected')
      {
        var _mustShow:boolean = this.over || this.selected;
        //console.log("over", this.over);
        if (!this.showing && _mustShow)
        {
          this.showing = true;
          gsap.to(this.ref.nativeElement, { scale: 1.05, duration: this.global.PROP_SCALE_DURATION, autoAlpha: 1 });
        }
        else if (this.showing && !_mustShow)
        {
          this.showing = false;
          gsap.to(this.ref.nativeElement, { scale: 1, duration: this.global.PROP_SCALE_DURATION, autoAlpha: 0 });
        }
      }
    }
  }

  _event_clickable_down(a_params: { index: number }): void
  {
    if (a_params != null && a_params.index == this.index)
    {
      //this.global.log("click", this.id);
      this.audio.playClickable();
      this.engine.pointnclick_action(this.index);
    }
  }
}
