import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';

import { Global, Status } from 'src/app/global';

import gsap from 'gsap';
import { AudioService } from 'src/app/services/audio.service';
import { EngineService } from 'src/app/services/engine.service';

/**
 * Ecran de connexion
 * 
 */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
  @ViewChild('groupinput', { read: ElementRef }) groupinputRef!: ElementRef;
  @ViewChild('groupinputOdysseus', { read: ElementRef }) groupinputOdysseusRef!: ElementRef;
  @ViewChild('code', { read: ElementRef }) codeRef!: ElementRef;
  @ViewChild('groupcheck', { read: ElementRef }) groupcheckRef!: ElementRef;
  @ViewChild('check', { read: ElementRef }) checkRef!: ElementRef;
  @ViewChild('button', { read: ElementRef }) buttonRef!: ElementRef;
  @ViewChild('links', { read: ElementRef }) linksRef!: ElementRef;
  @ViewChild('mentions', { read: ElementRef }) mentionsRef!: ElementRef;
  @ViewChild('confidentialite', { read: ElementRef }) confidentialiteRef!: ElementRef;

  public checked: boolean = false;
  public isOdysseusSession:boolean = (this.storage.uid === "e34facf3-3352-41e5-b251-2d782c47");

  constructor(
    private global: Global,
    private events: EventsService,
    private storage: StorageService,
    private data: DataService,
    private audio: AudioService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void
  {
    this.events.sendEvent("EVENT_TRANSITION_END");

    //const Odysseuspreprod = "e34facf3-3352-41e5-b251-2d782c47"
    //const Odysseusprod = "002fea09-b903-4253-872c-e9429d54";
  }

  // animation d'apparition
  ngAfterViewInit(): void
  {
    // apparition de l'écran de connexion
    let _tl = gsap.timeline().addLabel("start");

    //_tl.to(this.groupinputRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "start");
    if(this.isOdysseusSession){
      _tl.to(this.groupinputOdysseusRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "start");
    }else{
      _tl.to(this.groupinputRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "start");
    }
    _tl.to(this.groupcheckRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "<" + this.global.UI_CONNECT_DELAY);
    _tl.to(this.buttonRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "<" + this.global.UI_CONNECT_DELAY);
    _tl.to(this.linksRef.nativeElement, { autoAlpha: 1, duration: this.global.UI_FADE_DURATION }, "<" + this.global.UI_CONNECT_DELAY);
  }

  ngOnDestroy(): void
  {
  }

  onConnect()
  {
    // vérification de la case à cocher
    if (this.checkRef.nativeElement.checked!=true)
    {
      this.events.sendEvent("EVENT_CONFIRM_SHOW", [{ show: 'consent' }])
      return; 
    }

    if(this.isOdysseusSession === true){
      //const Odysseuspreprod = "e34facf3-3352-41e5-b251-2d782c47"
      //const Odysseusprod = "002fea09-b903-4253-872c-e9429d54";

      // on récupère le playerUID (puid) depuis l'URL
      const params = new URLSearchParams(location.search);
      let puid = params.get("puid");

      // puis on bloque le click sur le bouton de connexion (un appel API est fait sur la plateforme Odysseus donc il y a un léger délai)
      this.buttonRef.nativeElement.style.pointerEvents = 'none';

      this.storage.requestWebService("checkOrCreateOdysseusUser", this._checkOrCreateOdysseusUserCallback.bind(this), {
        puid: puid,
        project_FK: 1
      });
    } else {
      // sinon envoi du code au serveur
      this.storage.requestWebService("checkAccessCode", this._checkAccessCodeCallback.bind(this), {
        accessCode: this.codeRef.nativeElement.value
      });
    }

  }

  _checkOrCreateOdysseusUserCallback(a_answer: any)
  {
    if (a_answer?.json){
      // on initialise les données du joueur
      this.data.initUserData(a_answer.json);

      // on peut passer à la suite maintenant
      this._loginComplete();
    } else {
      this.events.sendEvent("EVENT_CONFIRM_SHOW", [{ show: 'connect' }]);
    }
  }

  _checkAccessCodeCallback(a_answer: any)
  {
    if (a_answer?.json)
    {
      // on initialise les données du joueur
      this.data.initUserData(a_answer.json);

      // on peut passer à la suite maintenant
      this._loginComplete();
    }
    else
      this.events.sendEvent("EVENT_CONFIRM_SHOW", [{ show: 'connect' }])
  }

  _loginComplete()
  {
    this.audio.playBg("ui", true);
    this.events.sendEvent("EVENT_LOGIN_COMPLETE");
  }


  onMentions()
  {
    this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: false }]);
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }]);
    this.events.sendEvent("EVENT_INF_POPUP_SHOW", [{ show: "mentions" }]);
  }

  onConfidentialite()
  {
    this.events.sendEvent("EVENT_BURGER_HIDE", [{ hide: false }]);
    this.events.sendEvent("EVENT_BURGER_MODE", [{ mode: "close" }]);
    this.events.sendEvent("EVENT_INF_POPUP_SHOW", [{ show: "confidentialite" }]);
  }

}
