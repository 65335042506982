<div [class]="classnames" [style]="style" (window:resize)="_resizeEvent($event)">
    <router-outlet></router-outlet>

    <app-button *ngIf="displayResBtn" #quick type="quick" (handler)="onQuick($event)"></app-button>
    <app-resources [popup]="true" *ngIf="respopup"></app-resources>
    <app-infos [popup]="true" [type]="infpopup" *ngIf="infpopup"></app-infos>

    <app-menu *ngIf="menu"></app-menu>
    <app-button #burger type="burger" (handler)="onBurger($event)"></app-button>

    <app-ellipse *ngIf="ellipse!=null" [content]="ellipse.content" [wait]="ellipse.wait"></app-ellipse>
    <app-confirm *ngIf="confirm" [type]="confirm"></app-confirm>
    <app-transition *ngIf="transition!=0" [status]="transition"></app-transition>
</div>
<div id="black0" [style]="black0"></div>
<div id="black1" [style]="black1"></div>